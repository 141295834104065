export const POPULAR_AMENITIES = [
    'WiFi',
    'FreeParking',
    'PaidParking',
    'AirConditioning',
    'Kitchen',
    'Kitchenette',
    'Balcony',
    'Terrace',
    'FlatScreenTv',
    'Kitchenware',
    'Pool',
    'SeaView',
    'LakeView',
    'StreetView',
    'GardenView',
    'PoolView',
    'RiverView',
    'MountainView',
];

export const MISCELLANEOUS_AMENITIES = [
    'WashingMachine',
    'Soundproof',
    'ChildrensCribs',
    'ClothesRack',
    'WasherDryer',
    'FoldUpBed',
    'TrashCans',
    'FreeSheetsAndTowels',
    'PaidSheetsAndTowels',
    'WalkInCloset',
    'Fan',
    'Fireplace',
    'Heating',
    'Iron',
    'HotTub',
    'PrivateEntrance',
    'Safe',
    'SittingArea',
    'TileFloor',
    'HardwoodFloor',
    'Desk',
    'Hypoallergenic',
    'CleaningProducts',
    'FeatherPillow',
    'NonFeatherPillow',
];

export const BATHROOM_AMENITIES = [
    'PrivateBathroom',
    'SharedBathroom',
    'ToiletPaper',
    'Bathtub',
    'Bidet',
    'Shower',
    'Bathrobe',
    'FreeToiletries',
    'Hairdryer',
    'Sauna',
    'Slippers',
];

export const MEDIA_AMENITIES = [
    'TV',
    'GameConsole',
    'PC',
    'Laptop',
    'Fax',
    'IPad',
    'Tablet',
    'CableChannels',
    'DVDPlayer',
    'BlueRayPlayer',
    'PayPerViewChannels',
    'CableSatellite',
];

export const FOOD_AMENITIES = [
    'DiningArea',
    'DiningTable',
    'BottleOfWater',
    'Cookies',
    'Fruit',
    'Wine',
    'Barbecue',
    'Oven',
    'Stove',
    'Toaster',
    'Dishwasher',
    'ElectricKettle',
    'OutdoorDiningArea',
    'OutdoorFurniture',
    'Microwave',
    'Refrigerator',
    'TeaCoffeeMaker',
];

export const SECURITY_AMENITIES = [
    'KeyCardAccess',
    'SmokeAlarm',
    'FireExtinguisher',
    'Lockers',
    'KeyAccess',
    'ExecutiveLoungeAccess',
    'AlarmClock',
    'WakeUpService',
];
