import {DEFAULT_LANGUAGE} from "./constants";

export const getLanguage = () => localStorage.getItem('bookingLanguage') || DEFAULT_LANGUAGE;

export const apiPropertyToState = (property) => {
    property.RoomList = (property.RoomList || []).map(room => {
        room.units = Array.from({length: room.UnitCount}, () => generateKey());

        if (room.Amenities.Balcony) {
            property.Balcony = true;
        }
        if (room.Amenities.Terrace) {
            property.Terrace = true;
        }
        if (room.Amenities.SeaView) {
            property.SeaView = true;
        }
        if (room.Amenities.LakeView) {
            property.LakeView = true;
        }
        if (room.Amenities.StreetView) {
            property.StreetView = true;
        }
        if (room.Amenities.GardenView) {
            property.GardenView = true;
        }
        if (room.Amenities.PoolView) {
            property.PoolView = true;
        }
        if (room.Amenities.RiverView) {
            property.RiverView = true;
        }
        if (room.Amenities.MountainView) {
            property.MountainView = true;
        }
        if (room.Amenities.TV
            || room.Amenities.GameConsole
            || room.Amenities.PC
            || room.Amenities.Laptop
            || room.Amenities.IPad
            || room.Amenities.Tablet
            || room.Amenities.CableChannels
            || room.Amenities.DVDPlayer
            || room.Amenities.BlueRayPlayer
            || room.Amenities.PayPerViewChannels
            || room.Amenities.CableSatellite
        ) {
            property.Media = true;
        }

        return room;
    });

    return property;
}

export const availabilitiesToState = data => data.reduce((acc, cur) => {
    acc[cur.RoomID] = {
        averagePrice: cur.AverageDayPrice,
        availableUnitCount: cur.AvailableUnitCount,
    };
    return acc;
}, {})

export const dailyAvailabilitiesToState = data => {
    for (let room in data) {
        data[room] = data[room].reduce((acc, cur) => {
            acc[cur.Date] = cur.Availability > 0 && cur.Price !== '0';
            return acc;
        }, {})
    }
    return data
}

export const generateKey = () =>
    'xxxx-xxxx-xxxx-xxxx'.replace(/x/g, c => {
        const r = Math.random() * 16 | 0
        const v = c === 'x'
            ? r
            : ((r & 0x3) | 0x8)

        return v.toString(16)
    })

export const roomHasAvailabilityConflicts = (conflicts, availabilities, chosenRooms, roomId) =>
    conflicts && availabilities[roomId].availableUnitCount < chosenRooms[roomId].length;
