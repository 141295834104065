import { defineMessages } from 'react-intl';

export default defineMessages({
    options: {
        id: 'options',
        defaultMessage: 'Options',
    },
    amenities: {
        id: 'amenities',
        defaultMessage: 'Amenities',
    },
    close: {
        id: 'close',
        defaultMessage: 'Close',
    },
    book: {
        id: 'book',
        defaultMessage: 'Book',
    },
    countRoomsSelected: {
        id: 'countRoomsSelected',
        defaultMessage: '{count, plural, one {{count} room} other {{count} rooms}} selected.',
    },
    checkIn: {
        id: 'checkIn',
        defaultMessage: 'Check in',
    },
    checkOut: {
        id: 'checkOut',
        defaultMessage: 'Check out',
    },
    nights: {
        id: 'nights',
        defaultMessage: 'Nights',
    },
    checkAvailability: {
        id: 'checkAvailability',
        defaultMessage: 'Check availability',
    },
    seeAllPhotos: {
        id: 'seeAllPhotos',
        defaultMessage: 'See all photos',
    },
    totalPrice: {
        id: 'totalPrice',
        defaultMessage: 'Total price:',
    },
    extraCharges: {
        id: 'extraCharges',
        defaultMessage: 'Extra charges:',
    },

    guestsCount: {
        id: 'room.guestsCount',
        defaultMessage: '{count, plural, one {{count} guest} other {{count} guests}}',
    },
    roomsCount: {
        id: 'room.roomsCount',
        defaultMessage: '{count, plural, one {{count} room} other {{count} rooms}}',
    },
    singleBedCount: {
        id: 'room.singleBedCount',
        defaultMessage: '{count, plural, one {{count} single bed} other {{count} single beds}}',
    },
    twinBedCount: {
        id: 'room.twinBedCount',
        defaultMessage: '{count, plural, one {{count} twin bed} other {{count} twin beds}}',
    },
    doubleBedCount: {
        id: 'room.doubleBedCount',
        defaultMessage: '{count, plural, one {{count} double bed} other {{count} double beds}}',
    },
    queenSizeBedCount: {
        id: 'room.queenSizeBedCount',
        defaultMessage: '{count, plural, one {{count} queen size bed} other {{count} queen size beds}}',
    },
    kingSizeBedCount: {
        id: 'room.kingSizeBedCount',
        defaultMessage: '{count, plural, one {{count} king size bed} other {{count} king size beds}}',
    },
    sofaBedCount: {
        id: 'room.sofaBedCount',
        defaultMessage: '{count, plural, one {{count} sofa} other {{count} sofas}}',
    },
    foldableBedCount: {
        id: 'room.foldableBedCount',
        defaultMessage: '{count, plural, one {{count} foldable bed} other {{count} foldable beds}}',
    },
    showAllAmenities: {
        id: 'room.showAllAmenities',
        defaultMessage: 'Room details',
    },
    averagePricePerNight: {
        id: 'room.averagePricePerNight',
        defaultMessage: 'Price per night for chosen dates.',
    },
    perNight: {
        id: 'room.perNight',
        defaultMessage: 'per night',
    },
    selectRoom: {
        id: 'room.selectRoom',
        defaultMessage: 'Select room',
    },
    deleteRoom: {
        id: 'room.deleteRoom',
        defaultMessage: 'Remove room',
    },
    selectedCount: {
        id: 'room.selectedCount',
        defaultMessage: '{count} selected',
    },
    adults: {
        id: 'room.adults',
        defaultMessage: 'Adults',
    },
    children: {
        id: 'room.children',
        defaultMessage: 'Children',
    },
    soldOut: {
        id: 'room.soldOut',
        defaultMessage: 'Sold out',
    },
    chooseDifferentDates: {
        id: 'room.chooseDifferentDates',
        defaultMessage: 'Please choose different dates.',
    },
    changeDates: {
        id: 'room.changeDates',
        defaultMessage: 'Change dates',
    },

    nightsCount: {
        id: 'footer.nightsCount',
        defaultMessage: '({count, plural, one {{count} night} other {{count} nights}})',
    },
    reservationNightsCount: {
        id: 'footer.reservationNightsCount',
        defaultMessage: 'Nights: {count}',
    },
    roomsSelectedCount: {
        id: 'footer.roomsSelectedCount',
        defaultMessage: '{count, plural, one {{count} room} other {{count} rooms}} selected',
    },
    selectRooms: {
        id: 'footer.selectRooms',
        defaultMessage: 'Please select a room.',
    },
    unitOrdinal: {
        id: 'footer.unitOrdinal',
        defaultMessage: 'Room {number} - select number of guests',
    },

    PrivateBathroom: {
        id: 'amenities.privateBathroom',
        defaultMessage: 'Private bathroom',
    },
    SharedBathroom: {
        id: 'amenities.sharedBathroom',
        defaultMessage: 'Shared bathroom',
    },
    ToiletPaper: {
        id: 'amenities.toiletPaper',
        defaultMessage: 'Toiled paper',
    },
    Bathtub: {
        id: 'amenities.bathtub',
        defaultMessage: 'Bathtub',
    },
    Bidet: {
        id: 'amenities.bidet',
        defaultMessage: 'Bidet',
    },
    Shower: {
        id: 'amenities.shower',
        defaultMessage: 'Shower',
    },
    Bathrobe: {
        id: 'amenities.bathrobe',
        defaultMessage: 'Bathrobe',
    },
    FreeToiletries: {
        id: 'amenities.freeToiletries',
        defaultMessage: 'Free toiletries',
    },
    Hairdryer: {
        id: 'amenities.hairdryer',
        defaultMessage: 'Hairdryer',
    },
    Sauna: {
        id: 'amenities.sauna',
        defaultMessage: 'Sauna',
    },
    Slippers: {
        id: 'amenities.slippers',
        defaultMessage: 'Slippers',
    },
    DiningArea: {
        id: 'amenities.diningArea',
        defaultMessage: 'Dining area',
    },
    DiningTable: {
        id: 'amenities.diningTable',
        defaultMessage: 'Dining table',
    },
    BottleOfWater: {
        id: 'amenities.bottleOfWater',
        defaultMessage: 'Bottle of water',
    },
    Cookies: {
        id: 'amenities.chocolate',
        defaultMessage: 'Chocolate/cookies',
    },
    Fruit: {
        id: 'amenities.fruit',
        defaultMessage: 'Fruit',
    },
    Wine: {
        id: 'amenities.wine',
        defaultMessage: 'Wine/Champagne',
    },
    Barbecue: {
        id: 'amenities.barbecue',
        defaultMessage: 'Barbecue',
    },
    Oven: {
        id: 'amenities.oven',
        defaultMessage: 'Oven',
    },
    Stove: {
        id: 'amenities.stove',
        defaultMessage: 'Stove',
    },
    Toaster: {
        id: 'amenities.toaster',
        defaultMessage: 'Toaster',
    },
    Dishwasher: {
        id: 'amenities.dishwasher',
        defaultMessage: 'Dishwasher',
    },
    ElectricKettle: {
        id: 'amenities.electricKettle',
        defaultMessage: 'Electric kettle',
    },
    OutdoorDiningArea: {
        id: 'amenities.outdoorDiningArea',
        defaultMessage: 'Outdoor dining area',
    },
    OutdoorFurniture: {
        id: 'amenities.outdoorFurniture',
        defaultMessage: 'Outdoor furniture',
    },
    Microwave: {
        id: 'amenities.microwave',
        defaultMessage: 'Microwave',
    },
    Refrigerator: {
        id: 'amenities.refrigerator',
        defaultMessage: 'Refrigerator',
    },
    TeaCoffeeMaker: {
        id: 'amenities.coffeeMaker',
        defaultMessage: 'Tea/coffee maker',
    },
    TV: {
        id: 'amenities.tv',
        defaultMessage: 'TV',
    },
    GameConsole: {
        id: 'amenities.gameConsole',
        defaultMessage: 'Game console',
    },
    PC: {
        id: 'amenities.pc',
        defaultMessage: 'PC',
    },
    Laptop: {
        id: 'amenities.laptop',
        defaultMessage: 'Laptop',
    },
    Fax: {
        id: 'amenities.fax',
        defaultMessage: 'Fax',
    },
    IPad: {
        id: 'amenities.iPad',
        defaultMessage: 'iPad',
    },
    Tablet: {
        id: 'amenities.tablet',
        defaultMessage: 'Tablet',
    },
    CableChannels: {
        id: 'amenities.cableChannels',
        defaultMessage: 'Cable channels',
    },
    DVDPlayer: {
        id: 'amenities.dvdPlayer',
        defaultMessage: 'DVD player',
    },
    BlueRayPlayer: {
        id: 'amenities.blueRayPlayer',
        defaultMessage: 'Blue-ray player',
    },
    PayPerViewChannels: {
        id: 'amenities.payPerViewChannels',
        defaultMessage: 'Pay-per-view channels',
    },
    CableSatellite: {
        id: 'amenities.cableSatellite',
        defaultMessage: 'Cable/Satellite',
    },
    WashingMachine: {
        id: 'amenities.washingMachine',
        defaultMessage: 'Washing machine',
    },
    Soundproof: {
        id: 'amenities.soundproof',
        defaultMessage: 'Soundproof',
    },
    ChildrensCribs: {
        id: 'amenities.cribs',
        defaultMessage: 'Cribs',
    },
    ClothesRack: {
        id: 'amenities.clothesRack',
        defaultMessage: 'Clothes rack',
    },
    WasherDryer: {
        id: 'amenities.washerDryer',
        defaultMessage: 'Washer/Dryer',
    },
    FoldUpBed: {
        id: 'amenities.foldUpBed',
        defaultMessage: 'Fold-up bed',
    },
    TrashCans: {
        id: 'amenities.trashCans',
        defaultMessage: 'Trash cans',
    },
    FreeSheetsAndTowels: {
        id: 'amenities.freeSheetsAndTowels',
        defaultMessage: 'Free sheets and towels',
    },
    PaidSheetsAndTowels: {
        id: 'amenities.paidSheetsAndTowels',
        defaultMessage: 'Extra charge sheets and towels',
    },
    WalkInCloset: {
        id: 'amenities.walkInCloset',
        defaultMessage: 'Walk-in closet',
    },
    Fan: {
        id: 'amenities.fan',
        defaultMessage: 'Fan',
    },
    Fireplace: {
        id: 'amenities.fireplace',
        defaultMessage: 'Fireplace',
    },
    Heating: {
        id: 'amenities.heating',
        defaultMessage: 'Heating',
    },
    Iron: {
        id: 'amenities.iron',
        defaultMessage: 'Iron',
    },
    HotTub: {
        id: 'amenities.hotTub',
        defaultMessage: 'Hot tub',
    },
    PrivateEntrance: {
        id: 'amenities.privateEntrance',
        defaultMessage: 'Private entrance',
    },
    Safe: {
        id: 'amenities.safe',
        defaultMessage: 'Safe',
    },
    SittingArea: {
        id: 'amenities.sittingArea',
        defaultMessage: 'Sitting area',
    },
    TileFloor: {
        id: 'amenities.tileFloor',
        defaultMessage: 'Tile/marble floor',
    },
    HardwoodFloor: {
        id: 'amenities.hardwoodFloor',
        defaultMessage: 'Hardwood/parquet floor',
    },
    Desk: {
        id: 'amenities.desk',
        defaultMessage: 'Desk',
    },
    Hypoallergenic: {
        id: 'amenities.hypoallergenic',
        defaultMessage: 'Hypoallergenic',
    },
    CleaningProducts: {
        id: 'amenities.cleaningProducts',
        defaultMessage: 'Cleaning products',
    },
    FeatherPillow: {
        id: 'amenities.featherPillow',
        defaultMessage: 'Feather pillow',
    },
    NonFeatherPillow: {
        id: 'amenities.nonFeatherPillow',
        defaultMessage: 'Non-feather pillow',
    },
    Wifi: {
        id: 'amenities.wifi',
        defaultMessage: 'Wi-Fi',
    },
    FreeParking: {
        id: 'amenities.freeParking',
        defaultMessage: 'Free parking',
    },
    PaidParking: {
        id: 'amenities.paidParking',
        defaultMessage: 'Paid parking',
    },
    AirConditioning: {
        id: 'amenities.airConditioning',
        defaultMessage: 'Air conditioning',
    },
    Kitchenette: {
        id: 'amenities.kitchenette',
        defaultMessage: 'Kitchenette',
    },
    Kitchen: {
        id: 'amenities.kitchen',
        defaultMessage: 'Kitchen',
    },
    Balcony: {
        id: 'amenities.balcony',
        defaultMessage: 'Balcony',
    },
    Terrace: {
        id: 'amenities.terrace',
        defaultMessage: 'Terrace',
    },
    FlatScreenTv: {
        id: 'amenities.flatScreenTV',
        defaultMessage: 'Flat screen TV',
    },
    Kitchenware: {
        id: 'amenities.kitchenware',
        defaultMessage: 'Kitchenware',
    },
    Pool: {
        id: 'amenities.pool',
        defaultMessage: 'Pool',
    },
    SeaView: {
        id: 'amenities.seaView',
        defaultMessage: 'Sea view',
    },
    LakeView: {
        id: 'amenities.lakeView',
        defaultMessage: 'Lake view',
    },
    StreetView: {
        id: 'amenities.streetView',
        defaultMessage: 'Street view',
    },
    GardenView: {
        id: 'amenities.gardenView',
        defaultMessage: 'Garden view',
    },
    PoolView: {
        id: 'amenities.poolView',
        defaultMessage: 'Pool view',
    },
    RiverView: {
        id: 'amenities.riverView',
        defaultMessage: 'River view',
    },
    MountainView: {
        id: 'amenities.mountainView',
        defaultMessage: 'Mountain view',
    },
    propertyType: {
        id: 'amenities.propertyType',
        defaultMessage: 'Property type',
    },
    hotel: {
        id: 'amenities.hotel',
        defaultMessage: 'Hotel',
    },
    apartment: {
        id: 'amenities.apartment',
        defaultMessage: 'Apartment',
    },
    guestHouse: {
        id: 'amenities.guestHouse',
        defaultMessage: 'Guest house',
    },
    bedAndBreakfast: {
        id: 'amenities.bedAndBreakfast',
        defaultMessage: 'Bed and breakfast',
    },
    homestay: {
        id: 'amenities.homestay',
        defaultMessage: 'Homestay',
    },
    holidayHome: {
        id: 'amenities.holidayHome',
        defaultMessage: 'Holiday home',
    },
    villa: {
        id: 'amenities.villa',
        defaultMessage: 'Villa',
    },
    hostel: {
        id: 'amenities.hostel',
        defaultMessage: 'Hostel',
    },
    motel: {
        id: 'amenities.motel',
        defaultMessage: 'Motel',
    },
    lodge: {
        id: 'amenities.lodge',
        defaultMessage: 'Lodge',
    },
    boat: {
        id: 'amenities.boat',
        defaultMessage: 'Boat',
    },
    wifiInPublicArea: {
        id: 'amenities.wifiInPublicArea',
        defaultMessage: 'Wi-Fi in public area',
    },
    wifiInRooms: {
        id: 'amenities.wifiInRooms',
        defaultMessage: 'Wi-Fi in rooms',
    },
    bySea: {
        id: 'amenities.bySea',
        defaultMessage: 'By a sea',
    },
    byLake: {
        id: 'amenities.byLake',
        defaultMessage: 'By a lake',
    },
    byRiver: {
        id: 'amenities.byRiver',
        defaultMessage: 'By a river',
    },
    inCityCenter: {
        id: 'amenities.inCityCenter',
        defaultMessage: 'In city center',
    },
    onMountain: {
        id: 'amenities.onMountain',
        defaultMessage: 'On a mountain',
    },
    urban: {
        id: 'amenities.urban',
        defaultMessage: 'In an urban surrounding',
    },
    rural: {
        id: 'amenities.rural',
        defaultMessage: 'In an rural surrounding',
    },
    beachfront: {
        id: 'amenities.beachfront',
        defaultMessage: 'Beachfront',
    },
    singleBed: {
        id: 'amenities.singleBed',
        defaultMessage: 'Single bed',
    },
    twinBed: {
        id: 'amenities.twinBed',
        defaultMessage: 'Twin bed',
    },
    doubleBed: {
        id: 'amenities.doubleBed',
        defaultMessage: 'Double bed',
    },
    queenSizeBed: {
        id: 'amenities.queenSizeBed',
        defaultMessage: 'Queen size bed',
    },
    kingSizeBed: {
        id: 'amenities.kingSizeBed',
        defaultMessage: 'King size bed',
    },
    sofaBed: {
        id: 'amenities.sofaBed',
        defaultMessage: 'Sofa bed',
    },
    foldableBed: {
        id: 'amenities.foldableBed',
        defaultMessage: 'Foldable bed',
    },
    basicAmenities: {
        id: 'amenities.basicAmenities',
        defaultMessage: 'Basics',
    },
    popularAmenities: {
        id: 'amenities.popularAmenities',
        defaultMessage: 'Popular',
    },
    miscellaneousAmenities: {
        id: 'amenities.miscellaneousAmenities',
        defaultMessage: 'Miscellaneous',
    },
    bathroomAmenities: {
        id: 'amenities.bathroomAmenities',
        defaultMessage: 'Bathroom',
    },
    mediaAmenities: {
        id: 'amenities.mediaAmenities',
        defaultMessage: 'Media',
    },
    foodAndDrinksAmenities: {
        id: 'amenities.foodAndDrinksAmenities',
        defaultMessage: 'Food and Drinks',
    },
    securityAndExtraAmenities: {
        id: 'amenities.securityAndExtraAmenities',
        defaultMessage: 'Security and Extras',
    },
    KeyCardAccess: {
        id: 'amenities.keyCardAccess',
        defaultMessage: 'Key card access',
    },
    KeyAccess: {
        id: 'amenities.keyAccess',
        defaultMessage: 'Key access',
    },
    SmokeAlarm: {
        id: 'amenities.smokeAlarm',
        defaultMessage: 'Smoke alarm',
    },
    FireExtinguisher: {
        id: 'amenities.fireExtinguisher',
        defaultMessage: 'Fire extinguisher',
    },
    Lockers: {
        id: 'amenities.lockers',
        defaultMessage: 'Lockers',
    },
    ExecutiveLoungeAccess: {
        id: 'amenities.executiveLoungeAccess',
        defaultMessage: 'Executive lounge Access',
    },
    AlarmClock: {
        id: 'amenities.alarmClock',
        defaultMessage: 'Alarm clock',
    },
    WakeUpService: {
        id: 'amenities.wakeUpService',
        defaultMessage: 'Wake-up service',
    },
    checkBookingDetails: {
        id: 'bookingForm.checkBookingDetails',
        defaultMessage: 'Please check your booking information.',
    },
    selectedDates: {
        id: 'bookingForm.selectedDates',
        defaultMessage: 'Selected dates',
    },
    selectedRooms: {
        id: 'bookingForm.selectedRooms',
        defaultMessage: 'Selected rooms',
    },
    fillTheForm: {
        id: 'bookingForm.fillTheForm',
        defaultMessage: 'Please fill in the form and confirm your booking.',
    },
    guestName: {
        id: 'bookingForm.guestName',
        defaultMessage: 'Name *',
    },
    guestSurname: {
        id: 'bookingForm.guestSurname',
        defaultMessage: 'Surname *',
    },
    guestPhone: {
        id: 'bookingForm.guestPhone',
        defaultMessage: 'Phone number',
    },
    guestEmail: {
        id: 'bookingForm.guestEmail',
        defaultMessage: 'Email address *',
    },
    countryOfResidence: {
        id: 'bookingForm.countryOfResidence',
        defaultMessage: 'Country of residence',
    },
    arrivalTime: {
        id: 'bookingForm.arrivalTime',
        defaultMessage: 'Arrival time',
    },
    comments: {
        id: 'bookingForm.comments',
        defaultMessage: 'Comments',
    },
    back: {
        id: 'bookingForm.back',
        defaultMessage: 'Back',
    },
    confirmBooking: {
        id: 'bookingForm.confirmBooking',
        defaultMessage: 'Confirm booking',
    },
    showDetails: {
        id: 'bookingForm.showDetails',
        defaultMessage: 'Show details',
    },
    totalAmount: {
        id: 'bookingForm.totalAmount',
        defaultMessage: 'Total: {amount}',
    },
    total: {
        id: 'bookingForm.total',
        defaultMessage: 'Total',
    },
    grandTotalAmount: {
        id: 'bookingForm.grandTotalAmount',
        defaultMessage: 'Grand total: {amount}',
    },
    numberOfNights: {
        id: 'bookingForm.numberOfNights',
        defaultMessage: 'Number of nights',
    },
    guests: {
        id: 'bookingForm.guests',
        defaultMessage: 'Guests',
    },
    price: {
        id: 'bookingForm.price',
        defaultMessage: 'Price',
    },
    bookingConfirmed: {
        id: 'bookingForm.bookingConfirmed',
        defaultMessage: 'Booking confirmed!',
    },
    successfullyBooked: {
        id: 'bookingForm.successfullyBooked',
        defaultMessage: 'You have successfully booked your stay in {propertyName}.',
    },
    checkYourEmail: {
        id: 'bookingForm.checkYourEmail',
        defaultMessage: 'Please check your email for booking information.',
    },
    ok: {
        id: 'bookingForm.ok',
        defaultMessage: 'Ok',
    },
    chooseCountry: {
        id: 'bookingForm.chooseCountry',
        defaultMessage: '-- choose a country --',
    },
    unavailable: {
        id: 'bookingForm.unavailable',
        defaultMessage: "Unavailable",
    },
    roomsUnavailable: {
        id: 'bookingForm.roomsUnavailable',
        defaultMessage: "We're sorry, some of the rooms are not available any more.",
    },
    priceChanged: {
        id: 'bookingForm.priceChanged',
        defaultMessage: "We're sorry, prices just changed.",
    },
    confirmUpdates: {
        id: 'bookingForm.confirmUpdates',
        defaultMessage: "If you are all right with these changes, just click Confirm Booking again. If you want to change your reservation, go back to the previous page.",
    },

    unknown: {
        id: 'unknown.unknown',
        defaultMessage: "This property does not exist. If you are sure that you have a correct address, try later, property's page might be inactive at the moment.",
    },
    seriousError: {
        id: 'error.seriousError',
        defaultMessage: "Oops, there was an error. We're sorry, please try again later.",
    },
    VAT: {
        id: 'error.VAT',
        defaultMessage: "VAT {inclusive}",
    },
    TouristTax: {
        id: 'error.TouristTax',
        defaultMessage: "Tourist tax {inclusive}",
    },
    CityTax: {
        id: 'error.CityTax',
        defaultMessage: "City tax {inclusive}",
    },
    CleaningFee: {
        id: 'error.CleaningFee',
        defaultMessage: "Cleaning fee {inclusive}",
    },
    TransferFee: {
        id: 'error.TransferFee',
        defaultMessage: "Transfer fee {inclusive}",
    },
    OtherFee: {
        id: 'error.OtherFee',
        defaultMessage: "Other fees {inclusive}",
    },
    inclusive: {
        id: 'error.inclusive',
        defaultMessage: " (inclusive)",
    },
})
