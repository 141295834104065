import React, { Component } from 'react';

export default class Input extends Component {

    handleChange(event) {
        const {
            onChange,
        } = this.props;

        if (typeof onChange === 'function') {
            onChange(event.currentTarget.value);
        }
    };

    render() {
        const {
            label,
            onChange,
            warning,
            ...props
        } = this.props;

        const inputClassName = warning
            ? 'text-input warning'
            : 'text-input';

        return (
            <div className="input">
                <label htmlFor={props.name}>{label}</label>
                <input
                    className={inputClassName}
                    onChange={this.handleChange.bind(this)}
                    {...props}
                />
            </div>
        )
    }
}
