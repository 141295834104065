import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";

import messages from '../messages';
import icons from "./icons";

export default class Unknown extends Component {
    render() {
        return (
            <div className="error">
                <div className="container">
                    {icons.dangerIcon()}
                    <div className="error-text">
                        <FormattedMessage {...messages.unknown} />
                    </div>
                </div>
            </div>
        )
    }
}
