import React, { Component } from 'react';

export default class Button extends Component {

    render() {
        const {
            className,
        } = this.props;

        const classes = className
            ? `button ${className}`
            : 'button';

        return (
            <button
                className={classes}
                {...this.props}
            />
        )
    }
}
