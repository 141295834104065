import React, { Component } from 'react';
import moment from 'moment';

import DatePicker from "../lib/DatePicker";
import Button from "../lib/Button";
import {
    DATE_FORMAT,
} from '../lib/constants';
import {
    POPULAR_AMENITIES,
} from './constants';
import Select from "../lib/Select";
import icons from "../lib/icons";
import {FormattedMessage, FormattedNumber, injectIntl} from "react-intl";
import messages from "../messages";
import './Room.scss';

class Room extends Component {

    constructor() {
        super(...arguments);

        this.renderCalendarDate = this.renderCalendarDate.bind(this);
        this.toggleAmenities = this.toggleAmenities.bind(this);
        this.state = {
            showAmenities: false,
        };
    }

    toggleAmenities() {
        this.setState({
            showAmenities: !this.state.showAmenities,
        });
    }

    renderCalendarDate(date) {
        const {
            checkIn,
            checkOut,
            dailyAvailabilities,
            room,
        } = this.props;

        const isAvailable = !date.isSameOrBefore(moment()) && dailyAvailabilities[room.ID][date.format(DATE_FORMAT)];
        const isSelected = date.isSameOrAfter(checkIn, 'day') && date.isSameOrBefore(checkOut, 'day');
        const isCheckIn = date.isSame(checkIn, 'day');
        const isCheckOut = date.isSame(checkOut, 'day');

        const className = 'rc-calendar-date'
            + (isSelected ? ' selected' : '')
            + (isAvailable ? ' available' : '')
            + (isCheckIn ? ' check-in' : '')
            + (isCheckOut ? ' check-out' : '');

        return (
            <div className={className}>
                {date.format('D')}
            </div>
        );
    }

    onChangeGuests(value, idx, guestType, key) {
        const {
            room,
            chosenGuests,
            chooseGuests,
        } = this.props;

        const newValue = chosenGuests.map((unit, index) =>
            index === idx
                ? {
                    adults: guestType === 'adults' ? value : unit.adults,
                    children: guestType === 'children' ? value : unit.children,
                    key,
                }
                : unit
        );
        return chooseGuests(room.ID, idx, newValue);
    }

    render() {
        const {
            room,
            fileUrlPrefix,
            property,
            chooseRooms,
            loadingPrice,
            chosenGuests,
            intl,
            showRoomSelectionWarning,
            showGuestsSelectionWarning,
            onChangeDates,
            deleteRoom,
            openDetails,
            checkIn,
            availabilities,
            loadingDailyAvailabilities,
        } = this.props;

        if (Object.keys(availabilities).length === 0) {
            return false
        }

        const price = availabilities[room.ID].averagePrice
        const freeUnitsCount = availabilities[room.ID].availableUnitCount;
        const isFull = freeUnitsCount === 0 || price === '0';
        const options = [];

        let current = 0;
        do {
            options.push({
                value: current,
                text: current === 0
                    ? intl.formatMessage(messages.selectRoom).toUpperCase()
                    : intl.formatMessage(messages.roomsCount, {count: current}),
                disabled: current > freeUnitsCount,
            });
            current++;
        } while (current <= room.UnitCount);

        const amenities = room.Amenities;
        const maxGuests = room.MaxGuests;

        const popular = POPULAR_AMENITIES.filter(name => amenities[name]);

        return (
            <div className="room-container">
                <div className="room">

                    <div className="main-image" onClick={openDetails}>
                        <img className="main-image" alt="hotel" src={`${fileUrlPrefix}${amenities.MainImage}`}/>
                    </div>

                    <div className="details">
                        <div className="container">

                            <div className="title">
                                {amenities.DisplayName || room.Name}
                            </div>

                            <div className="basic">
                                <div className="title">
                                    {amenities.DisplayName || room.Name}
                                </div>

                                <div className="guests-and-beds">
                                    <div className="guest-bed-count">
                                        {icons.guestIcon()}
                                        <span>
                                            <FormattedMessage {...messages.guestsCount} values={{count: maxGuests}} />
                                        </span>
                                    </div>

                                    {!!amenities.SingleBed &&
                                        <div className="guest-bed-count">
                                            {icons.singleBedIcon()}
                                            <span>
                                                <FormattedMessage
                                                    {...messages.singleBedCount}
                                                    values={{count: amenities.SingleBed}}
                                                />
                                            </span>
                                        </div>
                                    }

                                    {!!amenities.TwinBed &&
                                        <div className="guest-bed-count">
                                            {icons.twinBedIcon()}
                                            <span>
                                                <FormattedMessage
                                                    {...messages.twinBedCount}
                                                    values={{count: amenities.TwinBed}}
                                                />
                                            </span>
                                        </div>
                                    }

                                    {!!amenities.DoubleBed &&
                                        <div className="guest-bed-count">
                                            {icons.doubleBedIcon()}
                                            <span>
                                                <FormattedMessage
                                                    {...messages.doubleBedCount}
                                                    values={{count: amenities.DoubleBed}}
                                                />
                                            </span>
                                        </div>
                                    }

                                    {!!amenities.QueenDoubleBed &&
                                        <div className="guest-bed-count">
                                            {icons.doubleBedIcon()}
                                            <span>
                                                <FormattedMessage
                                                    {...messages.queenSizeBedCount}
                                                    values={{count: amenities.QueenDoubleBed}}
                                                />
                                            </span>
                                        </div>
                                    }

                                    {!!amenities.KingDoubleBed &&
                                        <div className="guest-bed-count">
                                            {icons.doubleBedIcon()}
                                            <span>
                                                <FormattedMessage
                                                    {...messages.kingSizeBedCount}
                                                    values={{count: amenities.KingDoubleBed}}
                                                />
                                            </span>
                                        </div>
                                    }

                                    {!!amenities.SofaBed &&
                                        <div className="guest-bed-count">
                                            {icons.sofaBedIcon()}
                                            <span>
                                                    <FormattedMessage
                                                        {...messages.sofaBedCount}
                                                        values={{count: amenities.SofaBed}}
                                                    />
                                            </span>
                                        </div>
                                    }

                                    {!!amenities.FoldableBed &&
                                        <div className="guest-bed-count">
                                            {icons.foldableBedIcon()}
                                            <span>
                                                <FormattedMessage
                                                    {...messages.foldableBedCount}
                                                    values={{count: amenities.FoldableBed}}
                                                />
                                            </span>
                                        </div>
                                    }
                                </div>

                                {isFull &&
                                    <div className="controls-info">
                                        <div className="sold-out">
                                            <FormattedMessage {...messages.soldOut} />
                                        </div>
                                    </div>
                                }

                                {!isFull &&
                                    <div className="price-container">
                                        <div className="price">
                                            {/*eslint-disable-next-line*/}
                                            <FormattedNumber value={price} currency="EUR" style="currency"/>
                                        </div>
                                        <span className="per-night">
                                            <FormattedMessage {...messages.perNight} />
                                        </span>
                                    </div>
                                }
                            </div>

                            <div className="show-amenities" onClick={openDetails}>
                                <FormattedMessage {...messages.showAllAmenities} />
                            </div>

                        </div>
                    </div>


                    {property.AvailabilityRule !== 'AnyUnitAvailable' && !loadingDailyAvailabilities &&
                        <div className="calendar">
                            <DatePicker
                                calendarProps={{
                                    disabledDate: () => true,
                                    showDateInput: false,
                                    showToday: false,
                                    dateRender: date => this.renderCalendarDate(date),
                                    disabled: true,
                                    value: checkIn,
                                }}
                                open
                                id={`room-${room.ID}`}
                            />
                        </div>
                    }

                    {property.AvailabilityRule === 'AnyUnitAvailable' &&
                        <div className="popular-amenities">
                            <ul className="amenities">
                                {popular.map(amenity => (
                                    <li key={amenity}>{amenity}</li>
                                ))}
                            </ul>
                        </div>
                    }

                    <div className="controls">
                        {isFull &&
                            <div className="controls-info">
                                <div className="sold-out">
                                    <FormattedMessage {...messages.soldOut} />
                                </div>
                                <FormattedMessage {...messages.chooseDifferentDates} />

                                <div className="change-dates">
                                    <span
                                        onClick={onChangeDates}
                                    >
                                        <FormattedMessage {...messages.changeDates} />
                                    </span>
                                </div>
                            </div>
                        }

                        {!isFull &&
                            <div className="controls-price">
                                <div className="price-container">
                                    { loadingPrice && icons.loaderDark() }

                                    {!loadingPrice && price !== undefined &&
                                        <>
                                            <div className="price">
                                                {/*eslint-disable-next-line*/}
                                                <FormattedNumber value={price} currency="EUR" style="currency" />
                                            </div>
                                            <span className="average-per-night">
                                                <FormattedMessage {...messages.averagePricePerNight} />
                                            </span>
                                        </>
                                    }
                                </div>

                                <div className="choose-rooms">
                                    <Select
                                        value={chosenGuests.length}
                                        options={options}
                                        disabled={loadingPrice}
                                        onChange={event => chooseRooms(
                                            room.ID,
                                            parseInt(event.currentTarget.value),
                                        )}
                                        className={
                                            showRoomSelectionWarning
                                                ? 'warning'
                                                : chosenGuests.length
                                                    ? 'green'
                                                    : ''
                                        }
                                        icon={chosenGuests.length
                                            ? icons.selectedRoomArrowIcon()
                                            : showRoomSelectionWarning
                                                ? icons.warningArrowIcon()
                                                : icons.roomsArrowIcon()}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {Array.isArray(chosenGuests) &&
                    chosenGuests.map((unit, idx) => {
                        const adults = unit.adults || room.MaxAdults;
                        const children = unit.children || 0;
                        const maxGuests = room.MaxGuests;
                        const maxAdults = room.MaxAdults;
                        const availableAdults = maxAdults - children;
                        const availableChildren = maxGuests - adults;

                        return (
                            <div className="unit" key={unit.key}>
                                <div className="name">
                                    <FormattedMessage
                                        {...messages.unitOrdinal}
                                        values={{number: idx + 1}}
                                    />
                                </div>

                                <div className="controls">
                                    <Select
                                        label={<FormattedMessage {...messages.adults} />}
                                        className={showGuestsSelectionWarning && !unit.adults && !unit.children ? 'warning' : ''}
                                        value={unit.adults || room.MaxAdults}
                                        options={
                                            Array.from({length: availableAdults}, (val, key) => ({
                                                value: key + 1,
                                                text: key + 1,
                                            }))
                                        }
                                        onChange={event => this.onChangeGuests(
                                            parseInt(event.currentTarget.value),
                                            idx,
                                            'adults',
                                            unit.key
                                        )}
                                        icon={showGuestsSelectionWarning && !unit.adults && !unit.children
                                            ? icons.warningArrowIcon()
                                            : icons.numberArrowIcon()
                                        }
                                    />

                                    <Select
                                        label={<FormattedMessage {...messages.children} />}
                                        className={showGuestsSelectionWarning && !unit.adults && !unit.children ? 'warning' : ''}
                                        value={unit.children || 0}
                                        options={[{
                                            value: 0,
                                            text: 0,
                                        }].concat(
                                            Array.from({length: availableChildren}, (val, key) => ({
                                                value: key + 1,
                                                text: key + 1,
                                            }))
                                        )}
                                        onChange={event => this.onChangeGuests(
                                            parseInt(event.currentTarget.value),
                                            idx,
                                            'children',
                                            unit.key
                                        )}
                                        icon={showGuestsSelectionWarning && !unit.adults && !unit.children
                                            ? icons.warningArrowIcon()
                                            : icons.numberArrowIcon()
                                        }
                                    />
                                </div>

                                <div className="delete">
                                <span
                                    onClick={() => deleteRoom(room.ID, unit.key)}
                                >
                                    X
                                </span>
                                    <Button
                                        onClick={() => deleteRoom(room.ID, unit.key)}
                                    >
                                        <FormattedMessage {...messages.deleteRoom} />
                                    </Button>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        );
    }
}

export default injectIntl(Room)
