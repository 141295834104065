import React, {Component} from 'react';
import moment from 'moment';
import {FormattedDate, FormattedMessage, FormattedNumber, injectIntl,} from 'react-intl';

import {
    getProperty,
    getProvisionalPrice,
    getPublicUrlPrefix,
    getCountryNames,
    getAvailability, getDailyAvailability
} from './lib/apiCall';
import DatePicker from './lib/DatePicker';
import {DATE_FORMAT,} from './lib/constants';
import Room from './Room/Room';
import './App.scss';
import Button from "./lib/Button";
import BookingForm from "./BookingForm/BookingForm";
import messages from './messages';
import icons from "./lib/icons";
import Modal from "./Modal/Modal";
import Skeleton from "./Skeleton/Skeleton";
import Language from "./lib/Language";
import Unknown from "./lib/Unknown";
import SeriousError from "./lib/SeriousError";
import PropertyDescription from "./PropertyDescription/PropertyDescription";

const initialState = {
    property: null,
    loading: true,
    fileUrlPrefix: null,
    loadingFileUrlPrefix: true,
    checkIn: moment().add(1, 'days'),
    checkOut: moment().add(8, 'days'),
    nights: 7,
    chosenRooms: {},
    bookingFormOpen: false,
    provisionalPrices: {},
    loadingProvisionalPrices: false,
    showRoomSelectionWarning: false,
    showModal: false,
    modalData: {},
    guestDetails: {
        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
        Country: '',
        ArrivalTime: '',
        Notes: '',
    },
    booked: false,
    countryNames: null,
    loadingCountryNames: true,
    nonExistingProperty: false,
    seriousError: false,
    loadingAvailabilities: true,
    availabilities: {},
    loadingDailyAvailabilities: true,
    dailyAvailabilities: {},
    dailyAvailabilitiesSpan: {},
};

const stickyFooterHeightLimit = 980;

class Layout extends Component {

    constructor() {
        super(...arguments);

        this.state = {
            stickyFooter: window.innerHeight > stickyFooterHeightLimit,
            ...initialState
        };
        this.stayRef = React.createRef();
        this.checkInRef = React.createRef();
        this.checkOutRef = React.createRef();

        this.onChangeCheckIn = this.onChangeCheckIn.bind(this);
        this.onChangeCheckOut = this.onChangeCheckOut.bind(this);
        this.onChooseRooms = this.onChooseRooms.bind(this);
        this.onBook = this.onBook.bind(this);
        this.closeBookingForm = this.closeBookingForm.bind(this);
        this.finishBooking = this.finishBooking.bind(this);
        this.onChosenRoomUpdate = this.onChosenRoomUpdate.bind(this);
        this.deleteRoom = this.deleteRoom.bind(this);
        this.openPropertyModal = this.openPropertyModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onGuestDetailsUpdate = this.onGuestDetailsUpdate.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onChangeDates = this.onChangeDates.bind(this);
        this.getProvisionalPrices = this.getProvisionalPrices.bind(this);
        this.getAvailabilities = this.getAvailabilities.bind(this);
    }

    componentDidMount() {
        getPublicUrlPrefix.call(this);
        getCountryNames.call(this, 'en');
        getProperty.call(
            this,
            initialState.checkIn.format(DATE_FORMAT),
            initialState.checkOut.format(DATE_FORMAT)
        );

        window.addEventListener("scroll", this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll)
    }

    onScroll() {
        this.setState({
            stickyFooter: window.innerHeight + window.scrollY > stickyFooterHeightLimit,
        })
    }

    onChooseRooms(roomId, count) {
        const chosenRooms = {...this.state.chosenRooms};
        const {
            property,
            checkIn,
            checkOut,
        } = this.state;

        const roomFromServer = property.RoomList.find(room => room.ID === roomId);
        const units = roomFromServer.units;

        count = parseInt(count);
        const room = chosenRooms[roomId];

        if (count === 0) {
            delete chosenRooms[roomId];

        } else if (Array.isArray(room)) {
            if (room.length > count) {
                chosenRooms[roomId] = room.slice(0, count);

            } else {
                chosenRooms[roomId] = room.concat(
                    Array.from({length: count - room.length}, (val, idx) =>({
                        adults: roomFromServer.MaxAdults,
                        children: 0,
                        key: units[idx + room.length],
                    }))
                );
            }

        } else {
            chosenRooms[roomId] = Array.from({length: count}, (val, idx) => ({
                    adults: roomFromServer.MaxAdults,
                    children: 0,
                    key: units[idx],
                })
            );
        }
        this.getProvisionalPrices(chosenRooms, checkIn, checkOut);

        this.setState({
            chosenRooms,
            showRoomSelectionWarning: false,
        });
    }

    findExcessChosenUnits() {
        const {
            chosenRooms,
            availabilities,
        } = this.state;

        const roomIds = Object.keys(chosenRooms);

        return roomIds.reduce((acc, roomId) => {
            const availableUnits = availabilities[roomId].availableUnitCount;
            const roomUnitsChosen = chosenRooms[roomId].length;

            if (roomUnitsChosen > availableUnits) {
                acc.push({roomId, count: availableUnits});
            }

            return acc;
        }, []);
    }

    removeExcessUnits(excessUnits) {
        const chosenRooms = {...this.state.chosenRooms};
        const {
            checkIn,
            checkOut,
        } = this.state;

        excessUnits.forEach(room => {
            const {
                roomId,
                count,
            } = room;

            if (count === 0) {
                delete chosenRooms[roomId];
            } else {
                chosenRooms[roomId] = chosenRooms[roomId].slice(0, count);
            }
        });
        this.getProvisionalPrices(chosenRooms, checkIn, checkOut);

        this.setState({
            chosenRooms,
        });
    }

    deleteRoom(roomId, unitId) {
        const chosenRooms = {...this.state.chosenRooms};
        const {
            checkIn,
            checkOut,
        } = this.state;

        chosenRooms[roomId] = chosenRooms[roomId].filter(unit =>
            unit.key !== unitId
        );

        if (!chosenRooms[roomId].length) {
            delete chosenRooms[roomId];
        }
        this.getProvisionalPrices(chosenRooms, checkIn, checkOut);

        this.setState({
            chosenRooms,
            showRoomSelectionWarning: false,
        });
    }

    onChosenRoomUpdate(roomId, unitIdx, value) {
        const {
            checkIn,
            checkOut,
        } = this.state;
        const chosenRooms = {...this.state.chosenRooms};

        chosenRooms[roomId] = value;

        this.getProvisionalPrices(chosenRooms, checkIn, checkOut);

        this.setState({
            chosenRooms: chosenRooms,
        })
    }

    onChangeCheckIn(value) {
        const {
            checkOut,
            chosenRooms,
        } = this.state;

        const newCheckOut = checkOut.isSameOrBefore(value, 'day')
            ? moment(value).add(1, 'days')
            : checkOut;

        this.setState({
            checkIn: value,
            nights: newCheckOut.diff(value, 'days'),
            loadingProvisionalPrices: true,
            checkOut: newCheckOut,
        });

        const excessUnits = this.findExcessChosenUnits(value, newCheckOut);
        if (excessUnits.length) {
            this.removeExcessUnits(excessUnits, value, newCheckOut);
        }

        this.checkOutRef.current.click();
        this.getAvailabilities(value, newCheckOut);
        this.getDailyAvailabilities(value, newCheckOut);
        this.getProvisionalPrices(chosenRooms, value, newCheckOut);
    }

    onChangeCheckOut(value) {
        const {
            checkIn,
            chosenRooms,
        } = this.state;

        this.setState({
            checkOut: value,
            nights: value.diff(checkIn, 'days'),
            loadingProvisionalPrices: true,
        });

        const excessUnits = this.findExcessChosenUnits(checkIn, value);
        if (excessUnits.length) {
            this.removeExcessUnits(excessUnits, checkIn, value);
        }
        this.getAvailabilities(checkIn, value);
        this.getDailyAvailabilities(checkIn, value);
        this.getProvisionalPrices(chosenRooms, checkIn, value);
    }

    onBook() {
        const {
            chosenRooms,
        } = this.state;

        if (!Object.keys(chosenRooms).length) {
            this.setState({
                showRoomSelectionWarning: true,
            })

        } else {
            this.setState({
                bookingFormOpen: true,
            });
        }
    }

    onGuestDetailsUpdate(guestDetails) {
        this.setState({
            guestDetails,
        })
    }

    onChangeDates() {
        window.scrollTo(0, this.stayRef.current.offsetTop);
        window.setTimeout(() => this.checkInRef.current.click(), 350);
    }

    getAvailabilities(checkIn, checkOut, callback) {
        const {
            property,
        } = this.state;

        if (checkIn.isBefore(checkOut, 'day')) {
            getAvailability.call(
                this,
                property.ID,
                checkIn.format(DATE_FORMAT),
                checkOut.format(DATE_FORMAT),
                callback
            )
            this.setState({
                loadingAvailabilities: true,
            });
        }
    }

    getDailyAvailabilities(checkIn, checkOut) {
        const {
            property,
            dailyAvailabilitiesSpan,
        } = this.state;

        const updatedCheckOut = checkOut.clone().add(1, 'month');
        if (checkIn.isBefore(dailyAvailabilitiesSpan.startDate)
            || updatedCheckOut.isAfter(dailyAvailabilitiesSpan.endDate)) {
            getDailyAvailability.call(this, property.ID, checkIn.format(DATE_FORMAT), updatedCheckOut.format(DATE_FORMAT));
            this.setState({
                loadingDailyAvailabilities: true,
            });
        }
    }

    closeBookingForm(clear) {
        this.setState({
            bookingFormOpen: false,
        });

        if (clear) {
            this.finishBooking(false);
        }
    }

    finishBooking(booked = true) {
        const {
            fileUrlPrefix,
            loadingFileUrlPrefix,
            countryNames,
        } = this.state;

        this.setState({
            ...initialState,
            fileUrlPrefix,
            loadingFileUrlPrefix,
            booked,
            countryNames,
            loadingCountryNames: false,
        });

        getProperty.call(
            this,
            initialState.checkIn.format(DATE_FORMAT),
            initialState.checkOut.format(DATE_FORMAT)
        );
    }

    getProvisionalPrices(chosenRooms, checkIn, checkOut, callback) {
        const {
            property,
        } = this.state;

        const rooms = property.RoomList;

        const checkInFormatted = checkIn.format(DATE_FORMAT);
        const checkOutFormatted = checkOut.format(DATE_FORMAT);

        const data = {
            CheckInDate: checkInFormatted,
            CheckOutDate: checkOutFormatted,
            RoomList: Object.keys(chosenRooms).map(roomId => ({
                RoomID: roomId,
                UnitList: chosenRooms[roomId].map(unit => ({
                    Adults: unit.adults || rooms.find(room => room.ID === roomId).MaxAdults
                })),
            })),
        };

        getProvisionalPrice.call(this, data, callback)
    }

    setProvisionalPrices(prices) {
        this.setState({
            loadingProvisionalPrices: false,
            provisionalPrices: {
                totalPrice: prices.TotalPrice,
                grandTotal: prices.TotalPriceWithAllCharges,
                extraCharges: prices.TotalExclusiveCharges,
                rooms: (prices.RoomList || []).reduce((acc, price) => {
                    acc[price.RoomID] = price.UnitList.map(unit => {
                        unit.ChargeList.sort((a, b) => {
                            if (a.IsInclusive > b.IsInclusive) {
                                return -1;
                            } else {
                                return 1;
                            }
                        })
                        return unit;
                    });
                    return acc;
                }, {})
            },
        })
    }

    openPropertyModal(data) {
        this.setState({
            showModal: true,
            modalData: data,
        });
    }

    closeModal() {
        this.setState({
            showModal: false,
            modalData: {},
            booked: false,
        });
    }

    render() {
        const {
            property,
            loading,
            fileUrlPrefix,
            loadingFileUrlPrefix,
            checkIn,
            checkOut,
            nights,
            chosenRooms,
            bookingFormOpen,
            loadingProvisionalPrices,
            showRoomSelectionWarning,
            showModal,
            modalData,
            guestDetails,
            booked,
            stickyFooter,
            provisionalPrices,
            countryNames,
            nonExistingProperty,
            seriousError,
            loadingAvailabilities,
            availabilities,
            loadingDailyAvailabilities,
            dailyAvailabilities,
        } = this.state;

        const {
            changeLanguage,
            language,
            locale,
        } = this.props;

        if (moment.isMoment(checkIn)) {
            checkIn.locale(locale);
        }
        if (moment.isMoment(checkOut)) {
            checkOut.locale(locale);
        }

        if (nonExistingProperty) {
            return <Unknown />
        }

        if (seriousError) {
            return <SeriousError />
        }

        if (loading || loadingFileUrlPrefix) {
            return (
                <Skeleton />
            )
        }
        if (!loading && !property) {
            return (
                <div className="no-property">
                    Property doesn't exist
                </div>
            )
        }

        if (bookingFormOpen) {
            return (
                <BookingForm
                    property={property}
                    checkIn={checkIn}
                    checkOut={checkOut}
                    chosenRooms={chosenRooms}
                    guestDetails={guestDetails}
                    finishBooking={this.finishBooking}
                    closeBookingForm={this.closeBookingForm}
                    updateGuest={this.onGuestDetailsUpdate}
                    prices={provisionalPrices}
                    countryNames={countryNames}
                    changeLanguage={changeLanguage}
                    language={language}
                    getProvisionalPrices={this.getProvisionalPrices}
                    getAvailabilities={this.getAvailabilities}
                    availabilities={availabilities}
                />
            );
        }

        const chosenRoomsCount = Object.keys(chosenRooms).length;

        const {
            Address,
            City,
            Country,
        } = property;

        const mapURI = `https://www.google.com/maps/search/?api=1&query=${Address},${City},${Country}`;
        const totalPrice = provisionalPrices.totalPrice;
        const extraCharges = provisionalPrices.extraCharges;
        const showExtraCharges = !loadingProvisionalPrices && extraCharges && extraCharges !== '0';
        const showTotalPrice = !loadingProvisionalPrices && totalPrice && totalPrice !== '0';

        return (
            <>
                <div className="App">
                    <Language
                        onChange={changeLanguage}
                        language={language}
                    />

                    <div className="header">
                        <h1 className="property-name">
                            {property.Name}
                        </h1>
                        <div className="header-strip">

                            <div className="image-container" onClick={this.openPropertyModal.bind(this, { property })}>
                                <img className="main-image" alt="hotel" src={`${fileUrlPrefix}${property.MainImage}`}/>
                                <div className="side-images">
                                    <img
                                        className="side-image-1"
                                        alt="hotel"
                                        src={`${fileUrlPrefix}${(property.Images && property.Images[0]) || property.MainImage}`}
                                    />
                                    <img
                                        className="side-image-2"
                                        alt="hotel"
                                        src={`${fileUrlPrefix}${(property.Images && property.Images[1]) || property.MainImage}`}
                                    />
                                    <div className="open-gallery">
                                        <FormattedMessage {...messages.seeAllPhotos} />
                                    </div>
                                </div>
                            </div>

                            <div className="property-description">
                                <a href={mapURI} target="_blank" rel="noopener noreferrer">
                                    <div className="location-icon">
                                        {icons.locationIcon()}
                                    </div>
                                    <div className="location-details">
                                        <div className="city-country">
                                            <span className="city">
                                                {`${City}, `}
                                            </span>
                                            <span className="country">
                                                {Country}
                                            </span>
                                        </div>
                                        <div className="address">
                                            {Address}
                                        </div>
                                    </div>
                                </a>
                                <div className="description">
                                    <PropertyDescription property={property} language={language}/>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="stay" ref={this.stayRef}>
                        <div className="container">
                            <DatePicker
                                label={<FormattedMessage {...messages.checkIn} />}
                                value={checkIn}
                                calendarProps={{
                                    disabledDate: day => day.isSameOrBefore(moment(), 'day'),
                                    showDateInput: false,
                                    showToday: false,
                                }}
                                onChange={this.onChangeCheckIn}
                                id="check-in"
                                inputRef={this.checkInRef}
                                disabled={loadingAvailabilities}
                                withIcon
                                placeBelow
                            />
                            <DatePicker
                                label={<FormattedMessage {...messages.checkOut} />}
                                value={checkOut}
                                calendarProps={{
                                    disabledDate: day => day.isSameOrBefore(checkIn, 'day')
                                        || day.isAfter(checkIn.clone().add(30, 'days'), 'day'),
                                    showDateInput: false,
                                    showToday: false,
                                }}
                                onChange={this.onChangeCheckOut}
                                id="check-out"
                                inputRef={this.checkOutRef}
                                disabled={loadingAvailabilities}
                                withIcon
                                placeBelow
                            />
                            <div className="nights-count">
                                <FormattedMessage {...messages.reservationNightsCount} values={{count: nights}}/>
                            </div>
                        </div>
                    </div>

                    <div className="rooms">
                        {property.RoomList.map(room =>
                            <Room
                                key={room.ID}
                                room={room}
                                property={property}
                                fileUrlPrefix={fileUrlPrefix}
                                checkIn={checkIn}
                                checkOut={checkOut}
                                chooseRooms={this.onChooseRooms}
                                chooseGuests={this.onChosenRoomUpdate}
                                loadingPrice={loadingAvailabilities}
                                chosenGuests={chosenRooms[room.ID] || []}
                                showRoomSelectionWarning={showRoomSelectionWarning}
                                onChangeDates={this.onChangeDates}
                                deleteRoom={this.deleteRoom}
                                openDetails={this.openPropertyModal.bind(this, { room })}
                                availabilities={availabilities}
                                dailyAvailabilities={dailyAvailabilities}
                                loadingDailyAvailabilities={loadingDailyAvailabilities}
                            />
                        )}
                    </div>
                </div>

                <footer className={stickyFooter ? "sticky" : ""}>
                    <div className="container">
                        <div className="info">
                            {(!!chosenRoomsCount || showRoomSelectionWarning) &&
                                <div className="selected-rooms">
                                    {showRoomSelectionWarning &&
                                    <>
                                        {icons.dangerIcon()}
                                        <span>
                                                <FormattedMessage {...messages.selectRooms} />
                                            </span>
                                    </>
                                    }
                                    {!!chosenRoomsCount &&
                                    <>
                                        {icons.confirmationIcon()}
                                        <span>
                                                <FormattedMessage {...messages.countRoomsSelected}
                                                                  values={{count: chosenRoomsCount}}/>
                                            </span>
                                    </>
                                    }
                                </div>
                            }
                        </div>

                        <div className={showExtraCharges ? 'price with-extra-charges' : 'price'}>
                            {showExtraCharges
                                ? <div className="price-container">
                                    <div>
                                    <span className="amount-label">
                                        <FormattedMessage {...messages.totalPrice} />
                                    </span>
                                        <div className="amount with-extra-charges">
                                            {/*eslint-disable-next-line*/}
                                            <FormattedNumber value={totalPrice} style={'currency'} currency="EUR" />
                                        </div>
                                    </div>
                                    <div>
                                    <span className="amount-label">
                                        <FormattedMessage {...messages.extraCharges} />
                                    </span>
                                        <div className="amount with-extra-charges amount-small">
                                            {/*eslint-disable-next-line*/}
                                            <FormattedNumber value={extraCharges} style={'currency'} currency="EUR" />
                                        </div>
                                    </div>
                                </div>
                                : showTotalPrice && <div className="price-container">
                                    <FormattedMessage {...messages.totalPrice} />
                                    <div className="amount">
                                        {/*eslint-disable-next-line*/}
                                        <FormattedNumber value={totalPrice} style={'currency'} currency="EUR" />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="controls">
                            <div className="dates">
                                <FormattedDate value={checkIn} /> - <FormattedDate value={checkOut} />
                            </div>
                            <div className="nights">
                                <FormattedMessage {...messages.nightsCount} values={{count: nights}} />
                            </div>
                            <div className="change-dates">
                                <span
                                    onClick={this.onChangeDates}
                                >
                                    <FormattedMessage {...messages.changeDates} />
                                </span>
                            </div>
                        </div>

                        <div className="book">
                            <Button
                                onClick={this.onBook}
                            >
                                <FormattedMessage {...messages.book} />
                                {icons.arrowIcon()}
                            </Button>
                        </div>
                    </div>
                </footer>

                {booked &&
                    <Modal
                        onClose={this.closeModal}
                        data={{propertyName: property.Name}}
                    />
                }

                {showModal &&
                    <Modal
                        onClose={this.closeModal}
                        data={modalData}
                        fileUrlPrefix={fileUrlPrefix}
                    />
                }
            </>
        );
    }
}

export default injectIntl(Layout);
