import React, { Component } from 'react';

export default class Select extends Component {

    render() {
        const {
            label,
            options,
            value,
            warning,
            icon,
            leftIcon,
            className,
            ...props
        } = this.props;

        const selectClassName = className
            ? `select-input ${className}`
            : 'select-input';

        return (
            <div className="input">
                {label &&
                    <label>{label}</label>
                }

                <div className="select">
                    {leftIcon}
                    <select
                        className={selectClassName}
                        onChange={this.handleChange}
                        value={value}
                        { ...props }
                    >
                        {Array.isArray(options) && options.map(option => (
                            <option
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                            >
                                {option.text}
                            </option>
                        ))}
                    </select>
                    {icon}
                </div>
            </div>
        )
    }
};
