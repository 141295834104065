import React, {Component} from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import messages from './messages';
import {YU_LANGUAGES} from "../lib/constants";

class PropertyDescription extends Component {

    filterPropsWithValues(...args) {
        return args.map(arg => ({
            name: arg,
            value: this.props.property[arg]
        })).filter(prop => prop.value);
    }

    renderParking() {
        const {
            property,
        } = this.props;

        const {
            FreeParking,
            PaidParking,
        } = property;

        if (FreeParking && PaidParking) {
            return <FormattedMessage {...messages.parkingBoth} />
        }
        if (FreeParking) {
            return <FormattedMessage {...messages.parkingFree} />
        }
        if (PaidParking) {
            return <FormattedMessage {...messages.parkingPaid} />
        }
        return false
    }

    renderMeals() {
        const {
            property,
        } = this.props;

        const {
            Restaurant,
            OfferMeals,
        } = property;

        if (Restaurant) {
            return <FormattedMessage {...messages.restaurant} />
        }
        if (OfferMeals) {
            return <FormattedMessage {...messages.offerMeals} />
        }
        return false
    }

    renderProximity() {
        const {
            property,
            intl,
        } = this.props;


        const filteredProps = this.filterPropsWithValues(
            'ProximityToBeach',
            'ProximityToSkiSlope',
            'ProximityToCityCenter',
            'ProximityToRestaurant',
            'ProximityToTouristAttractions',
            'ProximityToSportingGrounds',
            'ProximityToShopping',
            'ProximityToPublicTransport',
            'ProximityToAirport',
            'ProximityToTrainStation',
        );

        if (filteredProps.length === 0) {
            return false
        }
        if (filteredProps.length === 1) {
            const currentProp = filteredProps[0];
            const placeOfInterestMessage = currentProp.value < 1
                ? messages[`${currentProp.name}Meters`]
                : messages[currentProp.name];

            const placeOfInterestValue = currentProp.value < 1
                ? currentProp.value * 1000
                : currentProp.value;

            return <FormattedMessage
                {...messages.singlePlaceOfInterest}
                values={{
                    placeOfInterest: <FormattedMessage
                        {...placeOfInterestMessage}
                        values={{
                            proximity: placeOfInterestValue,
                        }}
                    />,
                    propertyType: <FormattedMessage {...messages[property.Type]} />,
                }}
            />
        }

        const places = filteredProps.reduce((acc, prop, idx, arr) => {
            const placeOfInterestMessage = prop.value < 1
                ? messages[`${prop.name}Meters`]
                : messages[prop.name];
            const placeOfInterestValue = prop.value < 1
                ? prop.value * 1000
                : prop.value;

            const current = intl.formatMessage(placeOfInterestMessage, {
                proximity: placeOfInterestValue,
            });

            acc += current;
            if (idx === arr.length - 2) {
                acc += intl.formatMessage(messages.and);
            } else if (idx < arr.length - 2) {
                acc += intl.formatMessage(messages.more);
            }
            return acc
        }, '');

        return <FormattedMessage
            {...messages.multiplePlacesOfInterest}
            values={{
                places,
            }}
        />
    }

    renderFirstSentence() {
        const {
            property,
            intl,
        } = this.props;

        const formatMessage = intl.formatMessage;

        const {
            WiFiInRooms,
            Balcony,
            Terrace,
            SeaView,
            LakeView,
            StreetView,
            GardenView,
            PoolView,
            RiverView,
            MountainView,
            InteriorType,
            RoomList,
            Type,
        } = property;

        const isSingle = RoomList.length === 1
            || ['Homestay', 'Villa', 'Lodge', 'HolidayHome', 'Boat', 'BedAndBreakfast'].indexOf(Type) !== -1;
        const isApartment = Type === 'Apartment';

        const view =
            (SeaView && isSingle ? formatMessage(messages.SeaView) : formatMessage(messages.SeaViewRooms))
            || (LakeView && isSingle ? formatMessage(messages.LakeView) : formatMessage(messages.LakeViewRooms))
            || (MountainView && isSingle ? formatMessage(messages.MountainView) : formatMessage(messages.MountainViewRooms))
            || (RiverView && isSingle ? formatMessage(messages.RiverView) : formatMessage(messages.RiverViewRooms))
            || (PoolView && isSingle ? formatMessage(messages.PoolView) : formatMessage(messages.PoolViewRooms))
            || (GardenView && isSingle ? formatMessage(messages.GardenView) : formatMessage(messages.GardenViewRooms))
            || (StreetView && isSingle ? formatMessage(messages.StreetView) : formatMessage(messages.StreetViewRooms));

        let rest = '';
        let offers = isSingle
            ? formatMessage(messages.offers)
            : isApartment
                ? formatMessage(messages.offer)
                : formatMessage(messages.offersRooms);
        let hasPrevious = false;

        if (InteriorType) {
            rest += isSingle
                ? formatMessage(
                    messages.interior,
                    {
                        style: formatMessage(messages[InteriorType])
                    })
                : formatMessage(
                    messages.interiors,
                    {
                        style: formatMessage(messages[InteriorType])
                    });

            offers = isSingle
                ? formatMessage(messages.andOffers)
                : isApartment
                    ? formatMessage(messages.andOffer)
                    : formatMessage(messages.andOffersRooms);
        }

        if (WiFiInRooms) {
            rest += offers;
            rest += isSingle || isApartment
                ? formatMessage(messages.wifi)
                : formatMessage(messages.wifiRooms);

            hasPrevious = true;
            offers = '';
        }

        if (Terrace) {
            rest += offers;
            if (hasPrevious) {
                rest += view || Balcony
                    ? formatMessage(messages.more)
                    : formatMessage(messages.and);
            }
            rest += isSingle
                ? formatMessage(messages.terrace)
                : formatMessage(messages.terraceRooms);

            hasPrevious = true;
            offers = '';
        }

        if (Balcony) {
            rest += offers;
            if (hasPrevious) {
                rest += view
                    ? formatMessage(messages.more)
                    : formatMessage(messages.and);
            }
            rest += isSingle
                ? formatMessage(messages.balcony)
                : formatMessage(messages.balconyRooms);

            hasPrevious = true;
            offers = '';
        }

        if (view) {
            rest += offers;
            if (hasPrevious) {
                rest += formatMessage(messages.and);
            }
            rest += view;
        }

        if (rest) {
            rest += formatMessage(messages.end);
        }

        return this.renderBase(rest);

    }

    renderBase(rest) {
        const {
            property,
        } = this.props;

        const {
            Type,
            Beachfront,
            Urban,
            Rural,
            Seaside,
            Lakeside,
            CityCenter,
            Riverside,
            Mountain,
        } = property;

        if (!Type || !rest) {
            return
        }

        const surrounding =
            (Urban && <FormattedMessage {...messages.urbanSurrounding} />)
            || (Rural && <FormattedMessage {...messages.ruralSurrounding} />);
        const location =
            (Seaside && <FormattedMessage {...messages.locationSea} />)
            || (Lakeside && <FormattedMessage {...messages.locationLake} />)
            || (CityCenter && <FormattedMessage {...messages.locationCityCenter} />)
            || (Riverside && <FormattedMessage {...messages.locationRiver} />)
            || (Mountain && <FormattedMessage {...messages.locationMountain} />);

        if (Beachfront) {
            return <FormattedMessage
                {...messages.beachfront}
                values={{
                    propertyType: Type,
                    rest,
                }}
            />
        }
        if (location && surrounding) {
            return <FormattedMessage
                {...messages.locationSurrounding}
                values={{
                    propertyType: Type,
                    location,
                    surrounding,
                    rest,
                }}
            />
        }
        if (location) {
            return <FormattedMessage
                {...messages.location}
                values={{
                    propertyType: Type,
                    location,
                    rest,
                }}
            />
        }
        if (surrounding) {
            return <FormattedMessage
                {...messages.surrounding}
                values={{
                    propertyType: Type,
                    surrounding,
                    rest,
                }}
            />
        }
        return false
    }

    renderOfferYu(publicWifi) {
        const {
            intl,
        } = this.props;

        const filteredProps = this.filterPropsWithValues(
            'Seaside',
            'Lakeside',
            'Riverside',
            'Mountain',
            'CityCenter',
            'Beachfront',
            'Urban',
            'Rural',
            'InteriorType',
            'WiFiInRooms',
            'SeaView',
            'LakeView',
            'StreetView',
            'GardenView',
            'PoolView',
            'RiverView',
            'MountainView',
            'Restaurant',
            'OfferMeals',
            'FreeParking',
            'PaidParking',
        );

        if (filteredProps.length === 0) {
            return ''
        }

        const values = filteredProps.slice(0, 7).reduce((acc, prop, idx, arr) => {
            let propMessage = messages[`${prop.name}Yu`];
            if (['Seaside', 'Lakeside', 'Riverside'].indexOf(prop.name) !== -1) {
                propMessage = messages.waterSideYu;
            } else if (['Urban', 'Rural'].indexOf(prop.name) !== -1) {
                propMessage = messages.surroundingYu;
            } else if (['SeaView',
                'LakeView',
                'StreetView',
                'GardenView',
                'PoolView',
                'RiverView'].indexOf(prop.name) !== -1) {
                propMessage = messages.viewYu;
            }

            if (propMessage) {
                const current = intl.formatMessage(propMessage, {
                    name: prop.name,
                    value: prop.value,
                });

                acc += current;
                if (idx < arr.length - 1) {
                    acc += intl.formatMessage(messages.more);
                } else {
                    acc += intl.formatMessage(messages.more);
                    if (publicWifi) {
                        acc += intl.formatMessage(messages.PublicWiFiYu);
                    }
                }
            }

            return acc
        }, '');

        return (
            <FormattedMessage {...messages.offerYu} values={{values}}/>
        )
    }

    renderYuDescription(publicWifi) {
        const {
            property,
        } = this.props;
        const {
            Type,
            RoomList,
        } = property;

        const welcome = (Type === 'Apartment' && RoomList.length > 1)
            ? <FormattedMessage {...messages.welcomeYuApartments} />
            : <FormattedMessage {...messages.welcomeYu} values={{
                    propertyType: Type,
                }}
            />;

        return (
            <>
                {welcome}
                {this.renderOfferYu(publicWifi)}
                {this.renderProximity()}
            </>
        )
    }

    render() {
        const {
            property,
            language,
        } = this.props;

        const customDescription = property[`Description${language[0].toUpperCase() + language.substring(1)}`]
        if (customDescription) {
            return (
                <div>
                    {customDescription}
                </div>
            )
        }

        const publicWifi = !property.WiFiInRooms && property.WiFiInPublicArea;
        const publicWifiMessage = <FormattedMessage {...messages.publicWifi} />;

        if (YU_LANGUAGES.indexOf(language) !== -1) {
            return this.renderYuDescription(publicWifi);
        }

        return (
            <div>
                {this.renderFirstSentence()}

                {publicWifiMessage}
                {this.renderParking()}
                {this.renderMeals()}
                {this.renderProximity()}
            </div>
        )
    }
}

export default injectIntl(PropertyDescription)
