import React, { Component } from 'react';
import Calendar from 'rc-calendar';
import RCDatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';

import {
    DATE_FORMAT,
} from './constants';
import icons from './icons';

export default class DatePicker extends Component {

    handleChange(value) {
        const {
            onChange,
        } = this.props;

        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

    getCalendarContainer(id) {
        return document.getElementById(id);
    }

    prepareDisplayValue(value) {
        return value
            ? value.format('L')
            : '';
    }

    render() {
        const {
            label,
            onChange,
            disabled,
            placeholder,
            calendarProps = {},
            id,
            withIcon,
            placeBelow,
            inputRef,
            ...props
        } = this.props;

        const calendar = (
            <Calendar
                format={DATE_FORMAT}
                {...calendarProps}
            />
        );

        return (
            <div
                className="input date-picker"
                id={id}
            >
                <label>{label}</label>
                {withIcon &&
                    icons.calendarIcon()
                }
                <RCDatePicker
                    getCalendarContainer={this.getCalendarContainer.bind(this, id)}
                    onChange={this.handleChange.bind(this)}
                    calendar={ calendar }
                    placement={placeBelow ? 'bottom' : 'top'}
                    align={{
                        points: ['tl', placeBelow ? 'bl' : 'tl'],
                        offset: [0, 0],
                        targetOffset: ['0%','0%'],
                        overflow: { adjustX: true, adjustY: true },
                    }}
                    { ...props }
                >
                    {({value}) => (
                        <input
                            className="date-picker-input"
                            value={ this.prepareDisplayValue(value) }
                            disabled={disabled}
                            readOnly={true}
                            placeholder={placeholder}
                            ref={inputRef}
                        />
                    )}
                </RCDatePicker>
                {withIcon &&
                    icons.languageArrowIcon()
                }
            </div>
        )
    }
}
