import { defineMessages } from 'react-intl';

export default defineMessages({
    Apartment: {
        id: 'description.Apartment',
        defaultMessage: 'apartment',
    },
    Hotel: {
        id: 'description.Hotel',
        defaultMessage: 'hotel',
    },
    Hostel: {
        id: 'description.Hostel',
        defaultMessage: 'hostel',
    },
    Motel: {
        id: 'description.Motel',
        defaultMessage: 'motel',
    },
    GuestHouse: {
        id: 'description.GuestHouse',
        defaultMessage: 'guest house',
    },
    Homestay: {
        id: 'description.Homestay',
        defaultMessage: 'homestay',
    },
    BedAndBreakfast: {
        id: 'description.BedAndBreakfast',
        defaultMessage: 'bed and breakfast',
    },
    HolidayHome: {
        id: 'description.HolidayHome',
        defaultMessage: 'holiday home',
    },
    Villa: {
        id: 'description.Villa',
        defaultMessage: 'villa',
    },
    Boat: {
        id: 'description.Boat',
        defaultMessage: 'boat',
    },
    Lodge: {
        id: 'description.Lodge',
        defaultMessage: 'lodge',
    },

    beachfront: {
        id: 'description.beachfront',
        defaultMessage: 'Our beachfront {propertyType, select, Apartment {apartments} Hotel {hotel} Hostel {hostel} Motel {motel} GuestHouse {guest house} Homestay {homestay} BedAndBreakfast {bed and breakfast} HolidayHome {holiday home} Villa {villa} Boat {boat} Lodge {lodge} other {apartments}} {rest}',
    },
    location: {
        id: 'description.location',
        defaultMessage: 'Situated {location}, our {propertyType, select, Apartment {apartments} Hotel {hotel} Hostel {hostel} Motel {motel} GuestHouse {guest house} Homestay {homestay} BedAndBreakfast {bed and breakfast} HolidayHome {holiday home} Villa {villa} Boat {boat} Lodge {lodge} other {apartments}} {rest}',
    },
    surrounding: {
        id: 'description.surrounding',
        defaultMessage: 'Situated {surrounding}, our {propertyType, select, Apartment {apartments} Hotel {hotel} Hostel {hostel} Motel {motel} GuestHouse {guest house} Homestay {homestay} BedAndBreakfast {bed and breakfast} HolidayHome {holiday home} Villa {villa} Boat {boat} Lodge {lodge} other {apartments}} {rest}',
    },
    locationSurrounding: {
        id: 'description.locationSurrounding',
        defaultMessage: 'Situated {surrounding} {location}, our {propertyType, select, Apartment {apartments} Hotel {hotel} Hostel {hostel} Motel {motel} GuestHouse {guest house} Homestay {homestay} BedAndBreakfast {bed and breakfast} HolidayHome {holiday home} Villa {villa} Boat {boat} Lodge {lodge} other {apartments}} {rest}',
    },
    locationSea: {
        id: 'description.locationSea',
        defaultMessage: 'by the sea',
    },
    locationLake: {
        id: 'description.locationLake',
        defaultMessage: 'by the lake',
    },
    locationRiver: {
        id: 'description.locationRiver',
        defaultMessage: 'by the river',
    },
    locationCityCenter: {
        id: 'description.locationCityCenter',
        defaultMessage: 'in the city centre',
    },
    locationMountain: {
        id: 'description.locationMountain',
        defaultMessage: 'on the mountain',
    },
    urbanSurrounding: {
        id: 'description.urbanSurrounding',
        defaultMessage: 'in urban surrounding',
    },
    ruralSurrounding: {
        id: 'description.ruralSurrounding',
        defaultMessage: 'in rural surrounding',
    },

    more: {
        id: 'description.more',
        defaultMessage: ', ',
    },
    and: {
        id: 'description.and',
        defaultMessage: ' and ',
    },
    end: {
        id: 'description.end',
        defaultMessage: '. ',
    },
    interior: {
        id: 'description.interior',
        defaultMessage: 'is furnished in {style} style',
    },
    interiors: {
        id: 'description.interiors',
        defaultMessage: 'are furnished in {style} style',
    },
    ModernInterior: {
        id: 'description.ModernInterior',
        defaultMessage: 'modern',
    },
    ClassicalInterior: {
        id: 'description.ClassicalInterior',
        defaultMessage: 'classical',
    },
    TraditionalInterior: {
        id: 'description.TraditionalInterior',
        defaultMessage: 'traditional',
    },
    IndustrialInterior: {
        id: 'description.IndustrialInterior',
        defaultMessage: 'industrial',
    },
    RusticInterior: {
        id: 'description.RusticInterior',
        defaultMessage: 'rustic',
    },
    LuxuryInterior: {
        id: 'description.LuxuryInterior',
        defaultMessage: 'luxurious',
    },
    OriginalInterior: {
        id: 'description.OriginalInterior',
        defaultMessage: 'original',
    },
    offer: {
        id: 'description.offer',
        defaultMessage: ' offer ',
    },
    andOffer: {
        id: 'description.andOffer',
        defaultMessage: ' and offer ',
    },
    offers: {
        id: 'description.offers',
        defaultMessage: ' offers ',
    },
    andOffers: {
        id: 'description.andOffers',
        defaultMessage: ' and offers ',
    },
    offersRooms: {
        id: 'description.offersRooms',
        defaultMessage: ' offers rooms with ',
    },
    andOffersRooms: {
        id: 'description.andOffersRooms',
        defaultMessage: ' and offers rooms with ',
    },
    wifi: {
        id: 'description.wifi',
        defaultMessage: 'wifi connection',
    },
    wifiRooms: {
        id: 'description.wifiRooms',
        defaultMessage: 'wifi connection',
    },
    terrace: {
        id: 'description.terrace',
        defaultMessage: 'terrace',
    },
    terraceRooms: {
        id: 'description.terraceRooms',
        defaultMessage: 'terraces',
    },
    balcony: {
        id: 'description.balcony',
        defaultMessage: 'balcony',
    },
    balconyRooms: {
        id: 'description.balconyRooms',
        defaultMessage: 'balconies',
    },
    SeaView: {
        id: 'description.seaView',
        defaultMessage: 'sea view',
    },
    LakeView: {
        id: 'description.lakeView',
        defaultMessage: 'lake view',
    },
    MountainView: {
        id: 'description.mountainView',
        defaultMessage: 'mountain view',
    },
    RiverView: {
        id: 'description.riverView',
        defaultMessage: 'river view',
    },
    PoolView: {
        id: 'description.poolView',
        defaultMessage: 'pool view',
    },
    GardenView: {
        id: 'description.gardenView',
        defaultMessage: 'garden view',
    },
    StreetView: {
        id: 'description.streetView',
        defaultMessage: 'street view',
    },
    SeaViewRooms: {
        id: 'description.SeaViewRooms',
        defaultMessage: 'sea view',
    },
    LakeViewRooms: {
        id: 'description.LakeViewRooms',
        defaultMessage: 'lake view',
    },
    MountainViewRooms: {
        id: 'description.MountainViewRooms',
        defaultMessage: 'mountain view',
    },
    RiverViewRooms: {
        id: 'description.RiverViewRooms',
        defaultMessage: 'river view',
    },
    PoolViewRooms: {
        id: 'description.PoolViewRooms',
        defaultMessage: 'pool view',
    },
    GardenViewRooms: {
        id: 'description.GardenViewRooms',
        defaultMessage: 'garden view',
    },
    StreetViewRooms: {
        id: 'description.StreetViewRooms',
        defaultMessage: 'street view',
    },

    publicWifi: {
        id: 'description.publicWifi',
        defaultMessage: 'Public area is covered by wifi connection. ',
    },
    parkingFree: {
        id: 'description.parkingFree',
        defaultMessage: 'There is an option for free parking. ',
    },
    parkingPaid: {
        id: 'description.parkingPaid',
        defaultMessage: 'There is an option for paid parking. ',
    },
    parkingBoth: {
        id: 'description.parkingBoth',
        defaultMessage: 'There is an option for both free and paid parking. ',
    },
    restaurant: {
        id: 'description.restaurant',
        defaultMessage: 'We’ll be happy to welcome you to our restaurant. ',
    },
    offerMeals: {
        id: 'description.offerMeals',
        defaultMessage: 'We’ll be more than happy to cook for you during your stay. ',
    },

    singlePlaceOfInterest: {
        id: 'description.singlePlaceOfInterest',
        defaultMessage: "It's just {placeOfInterest} from our {propertyType}. ",
    },
    multiplePlacesOfInterest: {
        id: 'description.multiplePlacesOfInterest',
        defaultMessage: 'Places of interest: {places}.',
    },
    ProximityToBeach: {
        id: 'description.ProximityToBeach',
        defaultMessage: '{proximity}km to a beach',
    },
    ProximityToBeachMeters: {
        id: 'description.ProximityToBeachMeters',
        defaultMessage: '{proximity}m to a beach',
    },
    ProximityToSkiSlope: {
        id: 'description.ProximityToSkiSlope',
        defaultMessage: '{proximity}km to a ski slope',
    },
    ProximityToSkiSlopeMeters: {
        id: 'description.ProximityToSkiSlopeMeters',
        defaultMessage: '{proximity}m to a ski slope',
    },
    ProximityToTouristAttractions: {
        id: 'description.ProximityToTouristAttractions',
        defaultMessage: '{proximity}km to the closest tourist attraction',
    },
    ProximityToTouristAttractionsMeters: {
        id: 'description.ProximityToTouristAttractionsMeters',
        defaultMessage: '{proximity}m to the closest tourist attraction',
    },
    ProximityToSportingGrounds: {
        id: 'description.ProximityToSportingGrounds',
        defaultMessage: '{proximity}km to sporting grounds',
    },
    ProximityToSportingGroundsMeters: {
        id: 'description.ProximityToSportingGroundsMeters',
        defaultMessage: '{proximity}m to sporting grounds',
    },
    ProximityToRestaurant: {
        id: 'description.ProximityToRestaurant',
        defaultMessage: '{proximity}km to the closest restaurant',
    },
    ProximityToRestaurantMeters: {
        id: 'description.ProximityToRestaurantMeters',
        defaultMessage: '{proximity}m to the closest restaurant',
    },
    ProximityToShopping: {
        id: 'description.ProximityToShopping',
        defaultMessage: '{proximity}km to closest shopping options',
    },
    ProximityToShoppingMeters: {
        id: 'description.ProximityToShoppingMeters',
        defaultMessage: '{proximity}m to closest shopping options',
    },
    ProximityToCityCenter: {
        id: 'description.ProximityToCityCenter',
        defaultMessage: '{proximity}km to the city center',
    },
    ProximityToCityCenterMeters: {
        id: 'description.ProximityToCityCenterMeters',
        defaultMessage: '{proximity}m to the city center',
    },
    ProximityToPublicTransport: {
        id: 'description.ProximityToPublicTransport',
        defaultMessage: '{proximity}km to public transportation',
    },
    ProximityToPublicTransportMeters: {
        id: 'description.ProximityToPublicTransportMeters',
        defaultMessage: '{proximity}m to public transportation',
    },
    ProximityToAirport: {
        id: 'description.ProximityToAirport',
        defaultMessage: '{proximity}km to airport',
    },
    ProximityToAirportMeters: {
        id: 'description.ProximityToAirportMeters',
        defaultMessage: '{proximity}m to airport',
    },
    ProximityToTrainStation: {
        id: 'description.ProximityToTrainStation',
        defaultMessage: '{proximity}km to train station',
    },
    ProximityToTrainStationMeters: {
        id: 'description.ProximityToTrainStationMeters',
        defaultMessage: '{proximity}m to train station',
    },
    welcomeYu: {
        id: 'description.welcomeYu',
        defaultMessage: 'Dobrodošli {propertyType, select, Apartment {u naš apartman} Hotel {u naš hotel} Hostel {u naš hostel} Motel {u naš motel} GuestHouse {u našu gostinjsku kuću} Homestay {u naše domaćinstvo} BedAndBreakfast {u naš bed and breakfast} HolidayHome {u našu kuću za odmor} Villa {u našu vilu} Boat {na naš brod} Lodge {u našu kolibu} other {u naš apartman}}. ',
    },
    welcomeYuApartments: {
        id: 'description.welcomeYuApartments',
        defaultMessage: 'Dobrodošli u naše apartmane. '
    },
    offerYu: {
        id: 'description.offerYu',
        defaultMessage: 'Iz sadržaja koje nudimo izdvajamo: {values}. ',
    },
    BeachfrontYu: {
        id: 'description.BeachfrontYu',
        defaultMessage: 'direktan izlaz na plažu',
    },
    CityCenterYu: {
        id: 'description.CityCenterYu',
        defaultMessage: 'u centru grada',
    },
    surroundingYu: {
        id: 'description.surroundingYu',
        defaultMessage: 'u {name, select, Urban {urbanom} Rural {ruralnom} other {urbanom}} okruženju',
    },
    waterSideYu: {
        id: 'description.waterSideYu',
        defaultMessage: 'na obali {name, select, Seaside {mora} Lakeside {jezera} Riverside {reke} other {mora}}',
    },
    MountainYu: {
        id: 'description.MountainYu',
        defaultMessage: 'na planini',
    },
    WiFiInRoomsYu: {
        id: 'description.WiFiInRoomsYu',
        defaultMessage: 'bežični internet u sobama',
    },
    BalconyYu: {
        id: 'description.BalconyYu',
        defaultMessage: 'balkon',
    },
    TerraceYu: {
        id: 'description.TerraceYu',
        defaultMessage: 'terasu',
    },
    viewYu: {
        id: 'description.viewYu',
        defaultMessage: 'pogled na {name, select, SeaView {more} LakeView {jezero} StreetView {ulicu} GardenView {dvorište} PoolView {bazen} RiverView {reku} MountainView {planinu} other {more}}',
    },
    InteriorTypeYu: {
        id: 'description.InteriorTypeYu',
        defaultMessage: '{value, select, ModernInterior {moderan} ClassicalInterior {klasičan} TraditionalInterior {tradicionalan} IndustrialInterior {industrijski} RusticInterior {rustičan} LuxuryInterior {luksuzan} OriginalInterior {originalan} other {moderan}} enterijer',
    },
    FreeParkingYu: {
        id: 'description.FreeParkingYu',
        defaultMessage: 'besplatan parking',
    },
    PaidParkingYu: {
        id: 'description.PaidParkingYu',
        defaultMessage: 'parking uz nadoknadu',
    },
    RestaurantYu: {
        id: 'description.RestaurantYu',
        defaultMessage: 'restoran u okviru objekta',
    },
    OfferMealsYu: {
        id: 'description.OfferMealsYu',
        defaultMessage: 'domaću kuhinju',
    },
    PublicWiFiYu: {
        id: 'description.PublicWiFiYu',
        defaultMessage: 'bežični internet u zajedničkim prostorijama',
    },
})
