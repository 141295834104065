import React, {Component} from 'react';
import './Skeleton.scss';

export default class Skeleton extends Component {

    renderRoom(index) {
        return (
            <div className="room-container" key={index}>
                <div className="room">

                    <div className="main-image">
                        <div className="main-image skeleton" />
                    </div>

                    <div className="details">
                        <div className="container">
                            <div className="basic">
                                <div className="title skeleton" />

                                <div className="guests">
                                    <div className="guest skeleton" />
                                    <div className="guest skeleton" />
                                    <div className="guest skeleton" />
                                </div>
                            </div>

                            <div className="popular-amenities">
                                <div className="amenity skeleton" />
                            </div>
                        </div>
                    </div>

                    <div className="controls">
                        <div className="controls-price">
                            <div className="price-container">
                                <div className="price skeleton" />
                            </div>

                            <div className="choose-rooms">
                                <div className="select skeleton" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="App skeleton-app">
                <div className="select-language">
                    <div className="input skeleton" />
                </div>

                <div className="header">
                    <div className="property-name skeleton" />

                    <div className="header-strip">
                        <div className="property-description">
                            <div className="location skeleton" />
                            <div className="description">
                                <div className="line skeleton" />
                                <div className="line skeleton" />
                                <div className="line skeleton" />
                            </div>
                        </div>
                        <div className="image-container">
                            <div className="main-image skeleton" />
                            <div className="side-images">
                                <div className="side-image-1 skeleton" />
                                <div className="side-image-2 skeleton" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="stay">
                    <div className="container">
                        <div className="input skeleton" />
                        <div className="input skeleton" />
                        <div className="input text-input skeleton" />
                        <div className="button skeleton" />
                    </div>
                </div>

                <div className="rooms">
                    {Array.from( {length: 4}, (val, index) => this.renderRoom(index))}
                </div>

            </div>
        )
    }

}
