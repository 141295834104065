import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import 'moment/locale/sr';

import Layout from "./Layout";
import './App.scss';
import {getLocale} from "./lib/apiCall";
import {DEFAULT_LANGUAGE} from "./lib/constants";
import {getLanguage} from "./lib/utils";

class App extends Component {

    constructor() {
        super(...arguments);

        this.state = {
            language: DEFAULT_LANGUAGE,
            messages: {},
        };

        this.onChangeLanguage = this.onChangeLanguage.bind(this);
    }

    componentDidMount() {
        const language = getLanguage();
        getLocale.call(this, language);
    }

    onChangeLanguage(event) {
        const value = event.currentTarget.value;

        localStorage.setItem('bookingLanguage', value)
        getLocale.call(this, value);
    }

    render() {
        const {
            language,
            messages,
        } = this.state;

        const updatedLocale = language === 'sr' || language === 'cnr'
            ? 'sr-Latn'
            : language;

        return (
            <IntlProvider
                defaultLocale={DEFAULT_LANGUAGE}
                locale={updatedLocale}
                key={language}
                messages={messages}
            >
                <Layout
                    changeLanguage={this.onChangeLanguage}
                    language={language}
                    locale={updatedLocale}
                />
            </IntlProvider>
        );
    }
}

export default App;
