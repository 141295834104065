import React, { Component } from 'react';
import {FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';

import {createReservations} from '../lib/apiCall';
import Select from "../lib/Select";
import Input from "../lib/Input";
import TextArea from "../lib/TextArea";
import Button from "../lib/Button";
import './BookingForm.scss';
import messages from '../messages';
import Language from "../lib/Language";
import Modal from "../Modal/Modal";
import dateOverview from './DateOverview';
import icons from "../lib/icons";
import {getLanguage, roomHasAvailabilityConflicts} from "../lib/utils";
import {DATE_FORMAT} from "../lib/constants";
import Unknown from "../lib/Unknown";
import SeriousError from "../lib/SeriousError";

const initialState = {
    disableBooking: true,
    showModal: false,
    showValidation: false,
    nonExistingProperty: false,
    seriousError: false,
    conflicts: false,
    oldPrices: null,
};

const emailRegEx = /\S+@\S+\.\S+/;

class BookingForm extends Component {

    constructor() {
        super(...arguments);

        this.onBook = this.onBook.bind(this);
        this.finishBooking = this.finishBooking.bind(this);
        this.onCloseBookingForm = this.onCloseBookingForm.bind(this);
        this.conflictsCallback = this.conflictsCallback.bind(this);

        this.state = initialState;
        this.formRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.showValidation && this.state.showValidation) {
            window.scrollTo(0, this.formRef.current.offsetTop);
        }
    }

    onCloseBookingForm() {
        this.props.closeBookingForm(this.state.conflicts);
    }

    onChange(propName, value) {
        const {
            updateGuest,
            guestDetails,
        } = this.props;

        this.setState({
            showValidation: false,
        });

        updateGuest({
            ...guestDetails,
            [propName]: value,
        });
    }

    onBook() {
        const {
            guestDetails = {},
            chosenRooms,
            checkIn,
            checkOut,
            prices,
            availabilities,
        } = this.props;

        const {
            FirstName,
            LastName,
            Email,
            Phone,
            Country,
            Notes,
        } = guestDetails;

        const conflicts = this.state.conflicts;

        if (!FirstName || !LastName || !Email || !emailRegEx.test(Email)) {
            this.setState({
                showValidation: true,
            });
            return
        }

        const checkInFormatted = checkIn.format(DATE_FORMAT);
        const checkOutFormatted = checkOut.format(DATE_FORMAT);

        const reservations = Object.keys(chosenRooms).map(roomId => {
            const hasConflicts = roomHasAvailabilityConflicts(conflicts, availabilities, chosenRooms, roomId);
            const units = hasConflicts
                ? chosenRooms[roomId].slice(0, availabilities[roomId].availableUnitCount)
                : chosenRooms[roomId];

            return {
                Price: prices.rooms[roomId][0].Price,
                PriceWithAllCharges: prices.rooms[roomId][0].PriceWithAllCharges,
                RoomID: roomId,
                UnitList: units.map((unit, idx) => ({
                    Adults: unit.adults,
                    Children: unit.children,
                    PriceWithAllCharges: prices.rooms[roomId][idx].PriceWithAllCharges,
                })),
            }
        });

        const data = {
            FirstName,
            LastName,
            Email,
            Phone,
            Country: Country || null,
            Notes,
            Language: getLanguage(),
            CheckInDate: checkInFormatted,
            CheckOutDate: checkOutFormatted,
            RoomList: reservations,
        };

        this.setState({
            conflicts: false,
            oldPrices: prices,
        });

        createReservations.call(this, data)
    }

    finishBooking() {
        this.setState(initialState);
        this.props.finishBooking();
    }

    conflictsCallback() {
        this.setState({
            showModal: true,
            conflicts: true
        })
    }

    bookingConflictsCallback(data) {
        const {
            getProvisionalPrices,
            getAvailabilities,
            checkIn,
            checkOut,
            chosenRooms,
        } = this.props;

        if (data.PriceChanged) {
            getProvisionalPrices(chosenRooms, checkIn, checkOut, this.conflictsCallback);
        } else if (data.NotAvailable) {
            getAvailabilities(checkIn, checkOut, this.conflictsCallback);
        }
    }

    isBookingAvailable(propName, value) {
        const {
            FirstName,
            LastName,
            Email,
        } = this.state;

        if (propName === 'FirstName') {
            return value !== ''
                && LastName !== ''
                && Email !== ''
        }
        if (propName === 'LastName') {
            return value !== ''
                && FirstName !== ''
                && Email !== ''
        }
        if (propName === 'Email') {
            return value !== ''
                && FirstName !== ''
                && LastName !== ''
        }
        return Email !== ''
            && FirstName !== ''
            && LastName !== ''
    }

    renderRooms() {
        const {
            property,
            chosenRooms,
            prices,
            availabilities,
        } = this.props;
        const conflicts = (this.state.conflicts || {}).rooms || {};

        if (!prices) {
           return (
               <div className="booking-rooms">
                   <div className="booking-room" />
               </div>
           )
        }

        const roomIds = Object.keys(chosenRooms);
        const rooms = roomIds.map(id => {
            const room = property.RoomList.find(room => room.ID === id);
            const count = roomHasAvailabilityConflicts(conflicts, availabilities, chosenRooms, id)
                ? chosenRooms[id].slice(0, availabilities[id].availableUnitCount)
                : chosenRooms[id].length;

            return {
                id,
                name: room.Amenities.DisplayName || room.Name,
                count: count,
            }
        });

        return (
            <div className="booking-rooms">
                {rooms.map(room =>
                    <div key={room.id} className="booking-room">
                        <span>
                            {room.name}
                        </span>
                        {room.count > 1
                            ? (
                                <span>
                                    {` x ${room.count}`}
                                </span>
                            )
                            : false
                        }
                    </div>
                )}
            </div>
        )
    }

    render() {
        const {
            property,
            checkIn,
            checkOut,
            guestDetails,
            chosenRooms,
            prices,
            countryNames,
            intl,
            changeLanguage,
            language,
            availabilities,
        } = this.props;

        const {
            showModal,
            showValidation,
            nonExistingProperty,
            seriousError,
            conflicts,
            oldPrices,
        } = this.state;

        if (nonExistingProperty) {
            return <Unknown />
        }

        if (seriousError) {
            return <SeriousError />
        }

        const countries = [{
            value: '',
            text: intl.formatMessage(messages.chooseCountry),
            disabled: true,
        }].concat(countryNames.map(country => ({
            value: country.Code,
            text: country.Name,
        })));

        const totalPriceConflict = oldPrices && oldPrices.grandTotal !== prices.grandTotal;

        return (
            <>
                <div className="booking-page-header-footer">
                    <div className="content">
                        <Language
                            onChange={changeLanguage}
                            language={language}
                        />

                        <div className="property-name">
                            {property.Name}
                        </div>
                    </div>
                </div>

                <div className="App">
                    <div className="booking-form">
                        <div
                            onClick={this.onCloseBookingForm}
                            className="back"
                        >
                            <FormattedMessage {...messages.back} />
                        </div>

                        <div className="step-header">
                            <FormattedMessage {...messages.checkBookingDetails} />
                        </div>

                        <div className="overview-section">
                            <div className="section-title">
                                <FormattedMessage {...messages.selectedDates} />
                            </div>

                            <div className="section-content">
                                {dateOverview(checkIn, messages.checkIn)}

                                {dateOverview(checkOut, messages.checkOut)}
                            </div>
                        </div>

                        <div className="overview-section">
                            <div className="section-title">
                                <FormattedMessage {...messages.selectedRooms} />
                            </div>

                            <div className="section-content">
                                {this.renderRooms()}

                                {totalPriceConflict &&
                                    <div className="total-amount total-amount-conflict">
                                        <FormattedMessage {...messages.totalAmount} values={{
                                            amount: <span>
                                                <FormattedNumber
                                                    value={oldPrices.grandTotal}
                                                    // eslint-disable-next-line
                                                    style={"currency"}
                                                    currency="EUR"
                                                />
                                            </span>,
                                        }} />
                                    </div>
                                }
                                <div className="overview-controls">
                                    <div
                                        className="show-details"
                                        onClick={() => this.setState({showModal: true})}
                                    >
                                        <FormattedMessage {...messages.showDetails} />
                                    </div>
                                    <div className="total-amount">
                                        {prices
                                            ? <FormattedMessage {...messages.totalAmount} values={{
                                                amount: <span>
                                                <FormattedNumber
                                                    value={prices.grandTotal}
                                                    // eslint-disable-next-line
                                                    style={"currency"}
                                                    currency="EUR"
                                                />
                                            </span>,
                                            }} />
                                            : <span className="price-skeleton" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="step-header" ref={this.formRef}>
                            <FormattedMessage {...messages.fillTheForm} />
                        </div>

                        <div className="form">
                            <form>
                                <Input
                                    label={<FormattedMessage {...messages.guestName} />}
                                    value={guestDetails.FirstName}
                                    onChange={this.onChange.bind(this, 'FirstName')}
                                    className={showValidation && !guestDetails.FirstName ? 'warning' : ''}
                                    autoComplete="on"
                                    name="given-name"
                                    id="given-name"
                                />
                                <Input
                                    label={<FormattedMessage {...messages.guestSurname} />}
                                    value={guestDetails.LastName}
                                    onChange={this.onChange.bind(this, 'LastName')}
                                    className={showValidation && !guestDetails.LastName ? 'warning' : ''}
                                    autoComplete="on"
                                    name="family-name"
                                    id="family-name"
                                />
                                <Input
                                    label={<FormattedMessage {...messages.guestEmail} />}
                                    value={guestDetails.Email}
                                    onChange={this.onChange.bind(this, 'Email')}
                                    className={showValidation
                                        && (!guestDetails.Email || !emailRegEx.test(guestDetails.Email))
                                            ? 'warning'
                                            : ''
                                    }
                                    autoComplete="on"
                                    name="email"
                                    id="email"
                                />
                                <Input
                                    label={<FormattedMessage {...messages.guestPhone} />}
                                    value={guestDetails.Phone}
                                    onChange={this.onChange.bind(this, 'Phone')}
                                    autoComplete="on"
                                    name="tel"
                                    id="tel"
                                />
                                <Select
                                    label={<FormattedMessage {...messages.countryOfResidence} />}
                                    value={guestDetails.Country}
                                    onChange={event => this.onChange('Country', event.currentTarget.value)}
                                    options={countries}
                                    icon={icons.languageArrowIcon()}
                                    autoComplete="on"
                                    name="country"
                                    id="country"
                                />
                                <Input
                                    label={<FormattedMessage {...messages.arrivalTime} />}
                                    value={guestDetails.ArrivalTime}
                                    onChange={this.onChange.bind(this, 'ArrivalTime')}
                                />
                                <TextArea
                                    label={<FormattedMessage {...messages.comments} />}
                                    value={guestDetails.Notes}
                                    onChange={this.onChange.bind(this, 'Notes')}
                                />

                                <div className="buttons">
                                    <Button
                                        onClick={this.onCloseBookingForm}
                                        type="button"
                                        className="back-button"
                                    >
                                        <FormattedMessage {...messages.back} />
                                    </Button>
                                    <Button
                                        onClick={this.onBook}
                                        type="button"
                                    >
                                        <FormattedMessage {...messages.confirmBooking} />
                                    </Button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <div className="booking-page-header-footer">
                    <div className="content">
                        <div className="property-name">
                            {property.Name}
                        </div>
                    </div>
                </div>

                {showModal &&
                    <Modal
                        onClose={() => this.setState({showModal: false})}
                        data={{
                            reservation: {
                                chosenRooms,
                                prices,
                                checkIn,
                                checkOut,
                                property,
                                conflicts,
                                oldPrices,
                                availabilities,
                            },
                        }}
                    />
                }
            </>
        );
    }
}

export default injectIntl(BookingForm)
