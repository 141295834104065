import {
    apiPropertyToState,
    availabilitiesToState, dailyAvailabilitiesToState,
} from "./utils";
import moment from "moment";

const baseUrl = '/api/v1/';
const pathName = window.location.pathname;
const baseName = pathName.slice(1);

const apiCall = (path, method, body) => {
    const url = baseUrl + path;

    const headers = new Headers();
    headers.set('Content-Type', 'application/json');

    const init = {
        method,
        headers,
        body: JSON.stringify(body),
    };

    const request = new Request(url, init);

    return fetch(request)
};

function handleResponse(response, callback) {
    const status = response.status;
    if (status === 404) {
        return this.setState({
            nonExistingProperty: true,
        });
    }

    if (status === 400 || response.status === 500) {
        return this.setState({
            seriousError: true,
        });
    }

    return callback.call(this);
}

export function getPublicUrlPrefix() {
    apiCall('file/public_url_prefix', 'GET').then(response => {
        handleResponse.call(this, response, () => {
            try {
                response.json().then(data => {
                    this.setState({
                        fileUrlPrefix: data.Prefix,
                        loadingFileUrlPrefix: false,
                    });
                });
            } catch (error) {
                return response.text().then(() => {
                    this.setState({
                        loadingFileUrlPrefix: false,
                    });
                });
            }
        });
    });
}

export function getLocale(lang) {
    apiCall(`locale/${lang}booking.json`, 'GET').then(response => {
        handleResponse.call(this, response, () => {
            try {
                response.json().then(data => {
                    this.setState({
                        messages: data,
                        language: lang,
                    });
                });
            } catch (error) {
                return response.text().then(() => {
                });
            }
        });
    });
}

export function getCountryNames(lang) {
    apiCall(`country-names/${lang}`, 'GET').then(response => {
        handleResponse.call(this, response, () => {
            try {
                response.json().then(data => {
                    this.setState({
                        countryNames: data.sort((a, b) => {
                            const aName = a.Name.toUpperCase();
                            const bName = b.Name.toUpperCase();
                            if (aName < bName) {
                                return -1;
                            } else if (aName > bName) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }),
                        loadingCountryNames: false,
                    });
                });
            } catch (error) {
                return response.text().then(() => {
                    this.setState({
                        loadingCountryNames: false,
                    });
                });
            }
        });
    });
}

export function getProperty(checkIn, checkOut) {
    apiCall(`public_property/${baseName}`, 'GET').then(response => {
        handleResponse.call(this, response, () => {
            try {
                response.json().then(data => {
                    this.setState({
                        property: apiPropertyToState(data),
                        loading: false,
                    });
                    getAvailability.call(this, data.ID, checkIn, checkOut);
                    getDailyAvailability.call(this, data.ID);
                });
            } catch (error) {
                return response.text().then(() => {
                    this.setState({
                        loading: false,
                    });
                });
            }
        });
    });
}

export function getAvailability(propertyId, checkIn, checkOut, callback) {
    const queryParams = `checkIn=${checkIn}&checkOut=${checkOut}`;

    apiCall(`reservations/${propertyId}/booking_page_availability?${queryParams}`, 'GET').then(response => {
        handleResponse.call(this, response, () => {
            try {
                response.json().then(data => {
                    this.setState({
                        availabilities: availabilitiesToState(data),
                        loadingAvailabilities: false,
                    });
                    if (typeof callback === 'function') {
                        callback(data);
                    }
                });
            } catch (error) {
                return response.text().then(() => {
                    this.setState({
                        loadingAvailabilities: false,
                    });
                });
            }
        });
    });
}

export function getProvisionalPrice(postData, callback) {
    apiCall(`reservations/provisional-prices`, 'POST', postData).then(response => {
        handleResponse.call(this, response, () => {
            try {
                response.json().then(data => {
                    this.setProvisionalPrices(data);
                    if (typeof callback === 'function') {
                        callback(data);
                    }
                });
            } catch (error) {
                return response.text().then(() => {
                    this.setState({
                        loadingProvisionalPrices: false,
                    });
                });
            }
        });
    });
}

export function createReservations(postData) {
    apiCall(`reservations/book`, 'POST', postData).then(response => {
        handleResponse.call(this, response, () => {

            try {
                response.json().then(data => {
                    if (response.status === 409) {
                        return this.bookingConflictsCallback(data);
                    }

                    return this.finishBooking();
                });
            } catch (error) {
                return response.text().then(() => {
                });
            }
        });
    });
}

export function getDailyAvailability(propertyId, first, last) {
    const queryParams = first && last
        ? `first=${first}&last=${last}`
        : 'firstOffset=0&lastOffset=90';

    apiCall(`property/${propertyId}/arr?${queryParams}`, 'GET').then(response => {
        handleResponse.call(this, response, () => {

            try {
                response.json().then(data => {
                    this.setState({
                        dailyAvailabilities: dailyAvailabilitiesToState(data.Availabilities),
                        loadingDailyAvailabilities: false,
                        dailyAvailabilitiesSpan: {
                            startDate: moment(data.StartDate),
                            endDate: moment(data.EndDate),
                        }
                    });
                });
            } catch (error) {
                return response.text().then(() => {
                });
            }
        });
    });
}
