import React, { Component } from 'react';

export default class TextArea extends Component {

    handleChange(event) {
        const {
            onChange,
        } = this.props;

        if (typeof onChange === 'function') {
            onChange(event.currentTarget.value);
        }
    };

    render() {
        const {
            label,
            onChange,
            ...props
        } = this.props;

        return (
            <div className="textarea">
                <label>{label}</label>
                <textarea
                    className="textarea-input"
                    onChange={this.handleChange.bind(this)}
                    {...props}
                />
            </div>
        )
    }
}
