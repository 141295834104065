import React, {Component} from 'react';
import {FormattedMessage, FormattedNumber} from "react-intl";
import messages from '../messages';
import icons from "../lib/icons";
import './Modal.scss';
import {
    BATHROOM_AMENITIES,
    FOOD_AMENITIES,
    MEDIA_AMENITIES,
    MISCELLANEOUS_AMENITIES,
    POPULAR_AMENITIES, SECURITY_AMENITIES
} from "../Room/constants";
import dateOverview from "../BookingForm/DateOverview";
import Button from "../lib/Button";
import {roomHasAvailabilityConflicts} from "../lib/utils";

export default class Modal extends Component {

    constructor() {
        super(...arguments);

        this.state = {
            currentImage: '',
            images: [],
        };

        this.onRightClick = this.onRightClick.bind(this);
        this.onLeftClick = this.onLeftClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        const {
            data,
        } = this.props;

        const {
            property,
            room,
        } = data;

        if (property) {
            const images = [property.MainImage || '']
                .concat(property.Images || [])
                .filter(img => img);

            this.setState({
                currentImage: images[0],
                images,
            })


        } else if (room) {
            const amenities = room.Amenities || {};
            const images = [amenities.MainImage || '']
                .concat(amenities.Images || [])
                .filter(img => img);

            this.setState({
                currentImage: images[0],
                images,
            })
        }

        window.addEventListener("keydown", this.onKeyDown)
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown)
        document.getElementsByTagName('body')[0].style.overflow = 'unset';
    }

    onKeyDown(event) {
        if (event.key === 'Escape') {
            event.stopPropagation();

            this.props.onClose()
        }
    }

    onLeftClick() {
        const {
            currentImage,
            images,
        } = this.state;

        const currentIndex = images.indexOf(currentImage);

        this.setState({
            currentImage: currentIndex > 0
                ? images[currentIndex - 1]
                : images[images.length - 1],
        });
    }

    onRightClick() {
        const {
            currentImage,
            images,
        } = this.state;

        const currentIndex = images.indexOf(currentImage);

        this.setState({
            currentImage: currentIndex === images.length - 1
                ? images[0]
                : images[currentIndex + 1],
        });
    }

    renderPropertyData() {
        return (
            <div className="modal-property">
                {this.renderGallery()}
            </div>
        )
    }

    renderRoomData(room) {
        const {
            Amenities,
            MaxGuests,
            Name,
        } = room;
        const {
            DisplayName,
            SingleBed,
            TwinBed,
            DoubleBed,
            QueenDoubleBed,
            KingDoubleBed,
            SofaBed,
            FoldableBed,
            ...amenities
        } = Amenities;

        const popular = POPULAR_AMENITIES.filter(name => amenities[name]);
        const miscellaneous = MISCELLANEOUS_AMENITIES.filter(name => amenities[name]);
        const bathroom = BATHROOM_AMENITIES.filter(name => amenities[name]);
        const media = MEDIA_AMENITIES.filter(name => amenities[name]);
        const foodAndDrinks = FOOD_AMENITIES.filter(name => amenities[name]);
        const securityAndExtras = SECURITY_AMENITIES.filter(name => amenities[name]);

        return (
            <div className="modal-room">
                <div className="mobile-room-name">
                    {DisplayName || Name}
                </div>

                <div className="mobile-gallery">
                    {this.renderGallery()}
                </div>

                <div className="room-basics">
                    <div className="room-name">
                        {DisplayName || Name}
                    </div>

                    <div className="guests">
                        <div className="section-title">
                            <FormattedMessage {...messages.options} />
                        </div>

                        <div className="guest-count">
                            {icons.guestIcon()}
                            <span>
                                <FormattedMessage {...messages.guestsCount} values={{count: MaxGuests}} />
                            </span>
                        </div>

                        <div className="beds">
                            {!!SingleBed &&
                                <div className="bed-count">
                                    {icons.singleBedIcon()}
                                    <span>
                                        <FormattedMessage
                                            {...messages.singleBedCount}
                                            values={{count: SingleBed}}
                                        />
                                    </span>
                                </div>
                            }

                            {!!TwinBed &&
                                <div className="bed-count">
                                    {icons.twinBedIcon()}
                                    <span>
                                        <FormattedMessage
                                            {...messages.twinBedCount}
                                            values={{count: TwinBed}}
                                        />
                                    </span>
                                </div>
                            }

                            {!!DoubleBed &&
                                <div className="bed-count">
                                    {icons.doubleBedIcon()}
                                    <span>
                                        <FormattedMessage
                                            {...messages.doubleBedCount}
                                            values={{count: DoubleBed}}
                                        />
                                    </span>
                                </div>
                            }

                            {!!QueenDoubleBed &&
                                <div className="bed-count">
                                    {icons.doubleBedIcon()}
                                    <span>
                                        <FormattedMessage
                                            {...messages.queenSizeBedCount}
                                            values={{count: QueenDoubleBed}}
                                        />
                                    </span>
                                </div>
                            }

                            {!!KingDoubleBed &&
                                <div className="bed-count">
                                    {icons.doubleBedIcon()}
                                    <span>
                                        <FormattedMessage
                                            {...messages.kingSizeBedCount}
                                            values={{count: KingDoubleBed}}
                                        />
                                    </span>
                                </div>
                            }

                            {!!SofaBed &&
                                <div className="bed-count">
                                    {icons.sofaBedIcon()}
                                    <span>
                                            <FormattedMessage
                                                {...messages.sofaBedCount}
                                                values={{count: SofaBed}}
                                            />
                                        </span>
                                </div>
                            }

                            {!!FoldableBed &&
                                <div className="bed-count">
                                    {icons.foldableBedIcon()}
                                    <span>
                                        <FormattedMessage
                                            {...messages.foldableBedCount}
                                            values={{count: FoldableBed}}
                                        />
                                    </span>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="amenities-container">
                        <div className="section-title">
                            <FormattedMessage {...messages.amenities} />
                        </div>

                        <ul className="amenities">
                            {popular.map(amenity => (
                                <li key={amenity}><FormattedMessage {...messages[amenity]}/></li>
                            ))}
                        </ul>

                        {miscellaneous.length
                            ? (
                                <>
                                    <div className="amenities-title">
                                        <FormattedMessage {...messages.miscellaneousAmenities} />
                                    </div>
                                    <ul className="amenities">
                                        {miscellaneous.map(amenity => (
                                        <li key={amenity}><FormattedMessage {...messages[amenity]}/></li>
                                    ))}
                                    </ul>
                                </>
                            )
                            : null
                        }

                        {bathroom.length
                            ? (
                                <>
                                    <div className="amenities-title">
                                        <FormattedMessage {...messages.bathroomAmenities} />
                                    </div>
                                    <ul className="amenities">
                                        {bathroom.map(amenity => (
                                            <li key={amenity}><FormattedMessage {...messages[amenity]}/></li>
                                        ))}
                                    </ul>
                                </>
                            )
                            : null
                        }

                        {media.length
                            ? (
                                <>
                                    <div className="amenities-title">
                                        <FormattedMessage {...messages.mediaAmenities} />
                                    </div>
                                    <ul className="amenities">
                                        {media.map(amenity => (
                                            <li key={amenity}><FormattedMessage {...messages[amenity]}/></li>
                                        ))}
                                    </ul>
                                </>
                            )
                            : null
                        }

                        {foodAndDrinks.length
                            ? (
                                <>
                                    <div className="amenities-title">
                                        <FormattedMessage {...messages.foodAndDrinksAmenities} />
                                    </div>
                                    <ul className="amenities">
                                        {foodAndDrinks.map(amenity => (
                                            <li key={amenity}><FormattedMessage {...messages[amenity]}/></li>
                                    ))}
                                    </ul>
                                </>
                            )
                            : null
                        }

                        {securityAndExtras.length
                            ? (
                                <>
                                    <div className="amenities-title">
                                        <FormattedMessage {...messages.securityAndExtraAmenities} />
                                    </div>
                                    <ul className="amenities">
                                        {securityAndExtras.map(amenity => (
                                            <li key={amenity}><FormattedMessage {...messages[amenity]}/></li>
                                    ))}
                                    </ul>
                                </>
                            )
                            : null
                        }

                    </div>

                </div>

                <div className="desktop-gallery">
                    {this.renderGallery()}
                </div>
            </div>
        )
    }

    renderGallery() {
        const {
            fileUrlPrefix,
            data,
        } = this.props;

        const isProperty = data.property;

        const {
            currentImage,
            images,
        } = this.state;

        return (
            <div className="gallery">
                <div className="current">
                    <img
                        className="image"
                        alt={isProperty ? 'hotel' : 'room'}
                        src={`${fileUrlPrefix}${currentImage}`}
                    />

                    {images.length > 1 &&
                        <>
                            <div className="left" onClick={this.onLeftClick}>
                                {icons.galleryLeftArrow()}
                            </div>
                            <div className="right" onClick={this.onRightClick}>
                                {icons.galleryRightArrow()}
                            </div>
                        </>
                    }

                </div>

                {images.length > 1 &&
                    <div className="thumbnails">
                        {images.map(image =>
                            <img
                                key={image}
                                className="image"
                                alt={isProperty ? 'hotel' : 'room'}
                                src={`${fileUrlPrefix}${image}`}
                                onClick={() => this.setState({
                                    currentImage: image,
                                })}
                            />
                        )}
                    </div>
                }
            </div>
        )
    }

    renderReservationData(reservation) {
        const {
            onClose,
        } = this.props;
        const {
            chosenRooms,
            prices,
            checkIn,
            checkOut,
            property,
            conflicts,
            oldPrices,
            availabilities,
        } = reservation;

        const nights = checkOut.diff(checkIn, 'days');
        const roomIds = Object.keys(chosenRooms);
        let unitCountConflicts = false;
        let roomPriceConflict = false;

        const units = roomIds.reduce((acc, id) => {
            const room = property.RoomList.find(room => room.ID === id);
            const availabilityConflict = roomHasAvailabilityConflicts(conflicts, availabilities, chosenRooms, id);
            const newUnitCount = availabilityConflict
                ? availabilities[id].availableUnitCount
                : chosenRooms[id].length;

            if (availabilityConflict) {
                unitCountConflicts = true;
            }

            const units = chosenRooms[id].map((unit, idx) => {
                let priceConflict = false;
                const hasOldPrice = oldPrices && oldPrices.rooms && oldPrices.rooms[id] && oldPrices.rooms[id][idx];
                if (hasOldPrice && prices.rooms[id][idx].PriceWithAllCharges !== oldPrices.rooms[id][idx].PriceWithAllCharges) {
                    priceConflict = true;
                    roomPriceConflict = true;
                }
                return {
                    name: room.Amenities.Name || room.Name,
                    guests: unit.adults + unit.children,
                    price: prices.rooms[id][idx],
                    oldPrice: hasOldPrice && oldPrices.rooms[id][idx],
                    unavailable: idx > (newUnitCount - 1),
                    key: unit.key,
                    priceConflict,
                };
            });

            return acc.concat(units);
        }, []);

        const totalPriceConflict = oldPrices && oldPrices.grandTotal !== prices.grandTotal;

        return (
            <div className="modal-reservation">
                {dateOverview(checkIn, messages.checkIn)}

                {dateOverview(checkOut, messages.checkOut)}

                <div className="conflicts-container">
                    {unitCountConflicts &&
                        <div className="conflict-container">
                            {icons.dangerIcon()}
                            <FormattedMessage {...messages.roomsUnavailable} />
                        </div>
                    }
                    {roomPriceConflict &&
                        <div className="conflict-container">
                            {icons.dangerIcon()}
                            <FormattedMessage {...messages.priceChanged} />
                        </div>
                    }
                    {(totalPriceConflict || unitCountConflicts) &&
                        <FormattedMessage {...messages.confirmUpdates} tagName="div"/>
                    }
                </div>

                <div className="selected-rooms">
                    <FormattedMessage {...messages.selectedRooms} />
                </div>

                {units.map(unit =>
                    <table key={unit.key}>
                        <thead>
                            <tr>
                                <th colSpan={unit.unavailable ? 1 : 2}>
                                    {unit.name}
                                </th>
                                {unit.unavailable &&
                                    <th>
                                        <span className="unavailable" >
                                            <FormattedMessage {...messages.unavailable} />
                                        </span>
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <FormattedMessage {...messages.numberOfNights} />
                                </td>
                                <td>
                                    <FormattedMessage {...messages.reservationNightsCount} values={{count: nights}}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <FormattedMessage {...messages.guests} />
                                </td>
                                <td>
                                    <FormattedMessage {...messages.guestsCount} values={{count: unit.guests}}/>
                                </td>
                            </tr>
                            {!unit.unavailable &&
                                <tr>
                                    <td>
                                        {this.renderUnitPriceLabels(unit)}
                                    </td>
                                    <td>
                                        {this.renderUnitPrice(unit)}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                )}

                {totalPriceConflict &&
                    <div className="requested-total-amount">
                        <FormattedMessage {...messages.totalAmount} values={{
                            amount: <span>
                                    <FormattedNumber
                                        value={oldPrices.totalPrice}
                                        // eslint-disable-next-line
                                        style={"currency"}
                                        currency="EUR"
                                    />
                                </span>,
                        }} />
                    </div>
                }

                <div className="total-amount">
                    <FormattedMessage {...messages.totalAmount} values={{
                        amount: <span>
                                <FormattedNumber
                                    value={prices.grandTotal}
                                    // eslint-disable-next-line
                                    style={"currency"}
                                    currency="EUR"
                                />
                            </span>,
                    }} />
                </div>

                <div className="controls">
                    <Button
                        onClick={onClose}
                        className="back-button"
                    >
                        <FormattedMessage {...messages.back} />
                    </Button>
                </div>
            </div>
        )
    }

    renderUnitPrice(unit) {
        const priceConflict = unit.priceConflict;
        const priceDetails = unit.price;
        const chargeList = priceDetails.ChargeList;

        if (unit.unavailable) {
            return (
                <span className="requested-price">
                    <FormattedNumber
                        value={priceDetails.PriceWithAllCharges || priceDetails.Price}
                        /*eslint-disable-next-line*/
                        style={"currency"}
                        currency="EUR" />
                </span>
            )
        } else {
            const hasChargeList = Array.isArray(chargeList) && chargeList.length;
            return (
                <>
                    <div className="price-line">
                        {priceConflict && unit.oldPrice.Price !== priceDetails.Price &&
                            <span className="price-conflict">
                                {/*eslint-disable-next-line*/}
                                <FormattedNumber value={unit.oldPrice.Price} style={"currency"} currency="EUR" />
                            </span>
                        }
                        {/*eslint-disable-next-line*/}
                        <FormattedNumber value={priceDetails.Price} style={"currency"} currency="EUR" />
                    </div>
                    {hasChargeList && chargeList.map((charge, idx) =>
                        <div className="price-line">
                            {priceConflict && priceDetails.ChargeList[idx].TotalPrice !== charge.TotalPrice &&
                                <span className="price-conflict">
                                {/*eslint-disable-next-line*/}
                                    <FormattedNumber value={priceDetails.ChargeList[idx].TotalPrice} style={"currency"} currency="EUR" />
                                </span>
                            }
                            {/*eslint-disable-next-line*/}
                            <FormattedNumber value={charge.TotalPrice} style={"currency"} currency="EUR" />
                        </div>
                    )}
                    {hasChargeList &&
                        <div className="unit-total-price">
                            {priceConflict && unit.oldPrice.PriceWithAllCharges !== priceDetails.PriceWithAllCharges &&
                                <span className="price-conflict">
                                {/*eslint-disable-next-line*/}
                                    <FormattedNumber value={unit.oldPrice.PriceWithAllCharges} style={"currency"}
                                                     currency="EUR"/>
                            </span>
                            }
                            {/*eslint-disable-next-line*/}
                            <FormattedNumber value={priceDetails.PriceWithAllCharges} style={"currency"}
                                             currency="EUR"/>
                        </div>
                    }
                </>
            )
        }
    }

    renderUnitPriceLabels(unit) {
        const chargeList = unit.price.ChargeList;
        if (Array.isArray(chargeList) && chargeList.length) {
            return (
                <>
                    <div className="price-line">
                        <FormattedMessage {...messages.price} />
                    </div>
                    {chargeList.map(charge =>
                        <div className="price-line">
                            {messages[charge.Type]
                                ? <FormattedMessage
                                    {...messages[charge.Type]}
                                    values={{
                                        inclusive: charge.IsInclusive && <FormattedMessage {...messages.inclusive} />
                                    }}
                                />
                                : <FormattedMessage
                                    {...messages.OtherFee}
                                    values={{
                                        inclusive: charge.IsInclusive && <FormattedMessage {...messages.inclusive} />
                                    }}
                                />
                            }
                        </div>
                    )}
                    <div className="unit-total-price">
                        <FormattedMessage {...messages.total} tagName="div" />
                    </div>
                </>
            )

        } else {
            return (
                <FormattedMessage {...messages.price} />
            )
        }
    }

    renderBookingConfirmation(propertyName) {
        const {
            onClose,
        } = this.props;

        return (
            <div className="booking-confirmation">
                <div className="confirmation-icon">
                    {icons.confirmationIcon()}
                </div>
                <div className="confirmation-title">
                    <FormattedMessage {...messages.bookingConfirmed} />
                </div>
                <div className="confirmation-text">
                    <FormattedMessage {...messages.successfullyBooked} values={{propertyName}} />
                </div>
                <div className="confirmation-text">
                    <FormattedMessage {...messages.checkYourEmail} />
                </div>

                <div className="controls">
                    <Button
                        onClick={onClose}
                        className="back-button"
                    >
                        <FormattedMessage {...messages.ok} />
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        const{
            onClose,
            data = {},
        } = this.props;
        const {
            property,
            room,
            reservation,
            propertyName,
        } = data;

        let content = false;

        if (property) {
            content = this.renderPropertyData(property)

        } else if (room) {
            content = this.renderRoomData(room)

        } else if (reservation) {
            content = this.renderReservationData(reservation)

        } else if (propertyName) {
            content = this.renderBookingConfirmation(propertyName)
        }

        const modalClassName = propertyName
            ? 'modal narrow'
            : 'modal';

        const closeClassName = propertyName
            ? 'close narrow'
            : 'close';

        return (
            <div className="modal-container">
                <div className="modal-overlay" onClick={onClose}>
                </div>
                <div className={closeClassName} onClick={onClose}>
                    <FormattedMessage {...messages.close} />
                </div>

                <div className={modalClassName}>
                    <div className="content-container">
                        <div className="content">
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
