import React from "react";
import {FormattedDate, FormattedMessage} from "react-intl";

export default (date, message) => (
    <div className="date-overview">
        <div className="date-title">
            <FormattedMessage {...message} />
        </div>
        <div className="date-value">
            <div>
                <FormattedDate
                    value={date}
                    weekday="long"
                />
            </div>
            <div>
                <FormattedDate
                    value={date}
                    month="long"
                    year="numeric"
                    day="numeric"
                />
            </div>
        </div>
    </div>
)
