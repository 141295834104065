import React, { Component } from "react";
import Select from "./Select";
import icons from './icons';

export default class Language extends Component {
    render() {
        return (
            <div className="select-language">
                <Select
                    options={[
                        {
                            value: 'en',
                            text: 'English',
                        },
                        {
                            value: 'sr',
                            text: 'srpski',
                        },
                        {
                            value: 'cnr',
                            text: 'crnogorski',
                        },
                    ]}
                    value={this.props.language}
                    onChange={this.props.onChange}
                    icon={icons.languageArrowIcon()}
                    leftIcon={icons.languageIcon()}
                />
            </div>
        )
    }
}